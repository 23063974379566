import styled from 'styled-components';

export const StyledDateInput = styled.input`
  padding: 10px;
  height: 37px;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #bbb;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  transition: border-color ease-out 0.3s;
  &:focus {
    border-color: #0078d7;
  }
`;

export const StyledIntroText = styled.p`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #555;
  margin: 5px 0;
  padding-left: 30px;
`;

export const StyledCalenderWrapper = styled.div`
  display: flex;
  max-width: 280px;
  padding-left: 30px;
`;

export const StyledDatePickerWrapper = styled.div`
  width: calc(100% - 60px);
  box-sizing: border-box;
`;

export const StyledPostBackButtonWrapper = styled.div`
  button {
    width: 60px;
    margin: 0;
    height: 37px;
    border-radius: 0 5px 5px 0;
  }
  box-sizing: border-box;
`;
