import React from 'react';
import Slider from 'react-slick';
import PostBackButton from '../PostBackButton';
import {
  StyledCarouselItem,
  StyledCarouselHeader,
  StyledCarouselSubheader,
  StyledCarouselNextButton,
  StyledCarouselPrevButton,
  StyledCarouselImage,
} from './styles';
import { IActivity } from '../../types';
import { EventTrackingService } from '../../services/events-tracking';

interface ICarouselProps {
  activity: IActivity;
}

const NextButton = props => {
  const onClick = (e: any) => {
    EventTrackingService.Instance.emit({
      name: 'CAROUSEL_NAVIGATION',
      payload: {
        direction: 'RIGHT',
      },
    });
    props.onClick(e);
  };

  return (
    <StyledCarouselNextButton
      className={props.className}
      style={{ ...props.style, zIndex: 20 }}
      onClick={onClick}
    />
  );
};

const PrevButton = props => {
  const onClick = (e: any) => {
    EventTrackingService.Instance.emit({
      name: 'CAROUSEL_NAVIGATION',
      payload: {
        direction: 'LEFT',
      },
    });
    props.onClick(e);
  };

  return (
    <StyledCarouselPrevButton
      className={props.className}
      style={{ ...props.style, zIndex: 20 }}
      onClick={onClick}
    />
  );
};

const Carousel: React.FC<ICarouselProps> = ({ activity }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  return (
    <Slider {...settings} style={{ marginBottom: 40 }}>
      {activity?.attachments?.length
        ? activity.attachments.map((carouselItem, index) => {
            return (
              <StyledCarouselItem key={index}>
                <StyledCarouselImage src={carouselItem.content?.images[0]?.url} />
                <StyledCarouselHeader>{carouselItem.content.text}</StyledCarouselHeader>
                <StyledCarouselHeader>{carouselItem.content.title}</StyledCarouselHeader>
                <StyledCarouselSubheader>{carouselItem.content.subtitle}</StyledCarouselSubheader>

                <div style={{ borderTop: '1px solid #e5e5e5' }}>
                  {carouselItem.content.buttons
                    ? carouselItem.content.buttons.map((buttonData, index) => (
                        <PostBackButton key={index} cardAction={buttonData} />
                      ))
                    : null}
                </div>
              </StyledCarouselItem>
            );
          })
        : null}
    </Slider>
  );
};

export default Carousel;
