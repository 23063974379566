import React from 'react';

import PostBackButton from '../PostBackButton';
import { CardActionTypes, ICardAction } from '../../types';

interface ICardActionButtonProps {
  cardAction: ICardAction;
}

export default ({ cardAction }: ICardActionButtonProps) => {
  switch (cardAction.type) {
    case CardActionTypes.GetUserLocation:
      return <PostBackButton cardAction={cardAction} />;

    case CardActionTypes.PostBack:
    default:
      return <PostBackButton cardAction={cardAction} />;
  }
};
