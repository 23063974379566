export const robotAvatarIcon =
  'https://firebasestorage.googleapis.com/v0/b/brain-ui-v1.appspot.com/o/bots%2Fkia_motors_corporation-MX-kian_development%2Frobot-avatar.png?alt=media&token=1b23ed2c-3548-4079-83f1-51a8095a3597';
export const carIcon =
  'https://firebasestorage.googleapis.com/v0/b/brain-ui-v1.appspot.com/o/bots%2Fkia_motors_corporation-MX-kian_development%2Fcar-icon.svg?alt=media&token=84be5262-fe92-455f-ac8a-6b1fc369eb3e';
export const magnifyingGlassIcon =
  'https://firebasestorage.googleapis.com/v0/b/brain-ui-v1.appspot.com/o/bots%2Fkia_motors_corporation-MX-kian_development%2Fmagnifying-glass-icon.svg?alt=media&token=0097b6a2-8312-4c2a-8b95-1bb7e71922ab';
export const supportHeadsetIcon =
  'https://firebasestorage.googleapis.com/v0/b/brain-ui-v1.appspot.com/o/bots%2Fkia_motors_corporation-MX-kian_development%2Fuser-icon.svg?alt=media&token=3d44d41d-d375-43a8-b6a5-36a0e35b27f9';
export const xmarkCrossIcon =
  'https://firebasestorage.googleapis.com/v0/b/brain-ui-v1.appspot.com/o/bots%2Fkia_motors_corporation-MX-kian_development%2Fxmark-icon.svg?alt=media&token=cdc1de9f-8bb9-419c-99c3-f745b5694aa5';
