import styled from 'styled-components';

export const StyledText = styled.p`
  padding: 10px;
  border-radius: ${props =>
    props.isBotMessage
      ? `${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px 0`
      : `${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px 0 ${props.theme.textBubbleRadius}px`};
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-size: ${props => props.theme.textFontSize}px;
  color: ${props =>
    props.isBotMessage ? `${props.theme.textBotColor}` : `${props.theme.textUserColor}`};
  background-color: ${props =>
    props.isBotMessage
      ? `${props.theme.textBotBackgroundColor}`
      : `${props.theme.textUserBackgroundColor}`};
`;

export const StyledTextWrapper = styled.div`
  margin-top: ${props => props.theme.distanceBetweenBubbles / 2}px;
  margin-bottom: ${props => props.theme.distanceBetweenBubbles / 2}px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: ${props => (props.isBotMessage ? 'flex-start' : 'flex-end')};
`;

export const StyledTextImage = styled.img`
  max-width: 22px;
  margin-right: 5px;
`;
