import styled, { keyframes } from 'styled-components';

export const StyledTypingIndicatorBlock = styled.div`
  align-items: center;
  display: flex;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 5px;
`;

const typingAnimation = keyframes`
  0% {
    transform:translateY(0px)
  }
  28% {
    transform:translateY(-5px)
  }
  44% {
    transform:translateY(0px)
  }
`;

export const StyledTypingIndicatorDot = styled.div`
  background-color: #90949c;
  animation: ${typingAnimation} 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 2px;
  width: 5px;
  &:nth-child(1) {
    animation-delay: 200ms;
  }
  &:nth-child(2) {
    animation-delay: 300ms;
  }
  &:nth-child(3) {
    animation-delay: 400ms;
  }
`;
