import styled, { keyframes } from 'styled-components';
import { AvatarAnimations } from '../../types';

export const StyledInputArea = styled.div`
  position: relative;
`;

const DEFAULT_AVATAR_IMAGE_WIDTH = 49;
const DEFAULT_AVATAR_IMAGE_HEIGHT = 53;
const DEFAULT_AVATAR_SHADOW_WIDTH = 52;

const scaleSize = (size: number, ratio: number) => {
  const multiplier = ratio / 100;
  return size * multiplier;
};

export const StyledAvatarWrapper = styled.div`
  right: -6px;
  position: fixed;
  z-index: 2;
  bottom: 25px;
  display: ${props => (props.theme.showAvatar && props.theme.showTeaser ? 'flex' : 'none')};
  @media (max-width: 576px) {
    z-index: 1;
  }
`;

export const StyledAvatar = styled.div`
  position: absolute;
  bottom: -15px;
  right: 30px;
`;

const animateAvatarBounce = keyframes`
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 4px;
  }
`;

const animateShadowBounce = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
`;

const determineAnimationStyle = (avatarAnimation: AvatarAnimations) => {
  // Default animation style
  let animation = {
    avatar: animateAvatarBounce,
    shadow: animateShadowBounce,
  };

  if (avatarAnimation === AvatarAnimations.Bounce) {
    animation = {
      avatar: animateAvatarBounce,
      shadow: animateShadowBounce,
    };
  }

  if (avatarAnimation === AvatarAnimations.Static) {
    animation = {
      avatar: '',
      shadow: '',
    };
  }

  return animation;
};

export const StyledAvatarImage = styled.div`
  width: ${props => scaleSize(DEFAULT_AVATAR_IMAGE_WIDTH, props.theme.avatarSize)}px;
  height: ${props => scaleSize(DEFAULT_AVATAR_IMAGE_HEIGHT, props.theme.avatarSize)}px;
  background: url(${props => props.theme.avatarImageUrl}) no-repeat top center;
  background-size: contain;
  animation-name: ${props => determineAnimationStyle(props.theme.avatarAnimation).avatar};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  cursor: pointer;
`;

export const StyledAvatarShadow = styled.div`
  width: ${props => scaleSize(DEFAULT_AVATAR_SHADOW_WIDTH, props.theme.avatarSize)}px;
  height: 12px;
  background: url(https://webchat.bmw.carlabs.com/images/sample-content/new-teaser/kian-shadow.png)
    no-repeat top center;
  background-size: 52px 12px;
  opacity: 1;
  animation-name: ${props => determineAnimationStyle(props.theme.avatarAnimation).shadow};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

export const StyledChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${props => props.theme.chatContainerHeight - props.theme.headerSize}px;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  border-radius: 0 0 20px 20px;

  @media (max-width: 576px) {
    width: 100%;
    height: 100%;
  }
`;

export const StyledChatLog = styled.div`
  box-sizing: border-box;
  padding: 16px 16px 10px 16px;
  overflow-y: auto;
`;

export const StyledChatMessageText = styled.p`
  display: block;
  max-width: 65%;
  background: linear-gradient(#5867fb, #3f51b5);
  border-radius: 14px;
  box-sizing: border-box;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 300;
  color: #e4e3e3;
  animation-duration: 0.3s;
  animation-name: fly-in;
`;

export const StyledSuggestedActions = styled.div`
  position: relative;
  width: 100%;
  background: ${props => props.theme.suggestedActionsBackgroundColor};
  border-top: ${props => props.theme.suggestedActionsBorder};
  padding-top: 2px;
  box-sizing: border-box;
`;

export const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  background: red;
  border: none;
  box-sizing: border-box;
  padding: 25px calc(30px + 20px) 25px 25px;
`;

export const StyledChatWrapper = styled.div`
  width: ${props => props.theme.chatContainerWidth}px;
  height: ${props => props.theme.chatContainerHeight}px;
  ${props => (props.isOpen ? null : 'display: none;')}
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem;
  border-radius: ${props => props.theme.chatContainerBorderRadius}px;
  position: fixed;
  bottom: ${props => scaleSize(DEFAULT_AVATAR_IMAGE_HEIGHT, props.theme.avatarSize) + 50}px;
  right: 30px;
  ${props =>
    props.theme.chatContainerBackgroundImageUrl
      ? `background-image: url(${props.theme.chatContainerBackgroundImageUrl});`
      : `background: ${props.theme.chatContainerBackgroundColor};`}
  }

  @media (max-width: 576px) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: ${props => props.theme.mobileHeight}%;
    z-index: 2;
  }
`;
