import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import simpleUpdateIn from 'simple-update-in';
import mergeDeep from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import { ICarLabsChat, IClientBotConfig } from './types';
import {
  getFirestoreSettings,
  Logger,
  getScriptAttribute,
  createRootDomElement,
  getUserIpAddress,
  getUserAgent,
  getUrlParams,
} from './util';
import { LocalStorageKeys, RootDomElementId, EmbedScript, AwsRegionEnum } from './types';
import * as serviceWorker from './serviceWorker';
import { EventTrackingService } from './services/events-tracking';
import { defaultBotStyle } from './components/App/constants';
import { Config } from './util/config';

declare global {
  interface Window {
    CarLabsChat: ICarLabsChat;
    CarLabs__addChannelDataPayload: (action: any, skipValidation?: boolean) => {};
  }
}

(async () => {
  const WEB_CHAT__SEND_MESSAGE = 'WEB_CHAT/SEND_MESSAGE';
  const DIRECT_LINE__POST_ACTIVITY = 'DIRECT_LINE/POST_ACTIVITY';

  // Fetch vendor ID from script tag embedded on clients webpage.
  let vendorUUID =
    getScriptAttribute(EmbedScript.ScriptUUID) || process.env.REACT_APP_LOCAL_VENDOR_UUID;
  if (!vendorUUID) return Logger('<CarLabsChat> No vendor UUID found...', false); // no vendorUUID, return to exit script silently.

  if (process.env.REACT_APP_LOCAL_VENDOR_UUID)
    Logger(
      `<CarLabsChat> Local Vendor UUID found... Vendor UUID: ${process.env.REACT_APP_LOCAL_VENDOR_UUID}.`,
      true,
    );

  // const _firestoreSettings = require('./test_config.json');
  const _firestoreSettings = await getFirestoreSettings(vendorUUID); // replace with vendorUUID.
  if (!_firestoreSettings) return Logger('<CarLabsChat> No config found...', true); // null was returned, exit script silently.
  Logger(`<CarLabsChat> Config Fetched: ${JSON.stringify(_firestoreSettings)}`, true);

  const botAwsRegion = _firestoreSettings.config.awsRegion || AwsRegionEnum.US_EAST_1;
  Logger(`<CarLabsChat> Region: ${botAwsRegion}`, true);

  const userIpAddress = await getUserIpAddress();
  Logger(`<CarLabsChat> User IP: ${userIpAddress}`, true);

  const userAgent = getUserAgent();
  Logger(`<CarLabsChat> User Agent: ${userAgent}`, true);

  const _directlineToken = Config.getDirectlineToken(botAwsRegion);
  if (!_directlineToken) return Logger('<CarLabsChat> No token found...', true); // null was returned, exit script silently.
  Logger(`<CarLabsChat> Token found: ${_directlineToken}`, true);

  // Determine if this script is being used for internal usage (webchat)
  // or by a client by inspecting the data-script-usage attr of the script.
  // A null script usage indicates its being used by client, otherwise it would
  // have a value of internal or some other arbitrary string.
  const scriptUsage = getScriptAttribute(EmbedScript.ScriptUsage);
  Logger(`<CarLabsChat> Is for internal usage: ${!!scriptUsage}`, true);

  // Bot Methods exposed to client.
  const _init = (initClientBotConfig: IClientBotConfig) => {
    Logger(
      `<CarLabsChat> Initializing... Environment: ${Config.getEnvironment()}. Version: ${
        process.env.REACT_APP_VERSION
      }.`,
      true,
    );

    // Its possible that clients may programatically init and forget to include
    // certain properties in the config object they pass us. For this reason, we
    // will comebine what was passed to the init method as well as the config
    // we fetched from the db and the default style.
    const originalConfigFromDatabase = cloneDeep(_firestoreSettings); // firestoreSettings is reference type, clone to avoid override
    const combinedWithInitConfig = mergeDeep(originalConfigFromDatabase, initClientBotConfig);
    const combinedConfig = mergeDeep({ styles: defaultBotStyle }, combinedWithInitConfig);

    // If clearPreviousSessionOnInit enabled, clear session before init happens.
    if (combinedConfig.clearPreviousSessionOnInit) _clearHistory();

    // When initing the bot, we need to make sure that the root element we
    // attach the bot to exists. If it doesnt, we create our default root element.
    const rootElementId = combinedConfig.rootElementId || RootDomElementId.CarlabsRootElement;
    const el = document.getElementById(rootElementId);

    if (el) _destroy(rootElementId);
    else createRootDomElement(rootElementId);

    EventTrackingService.Instance.emit({
      name: 'BOT_STATE__INIT',
    });

    const getActionTypeChannelDataPath = (actionType: string) => {
      switch (actionType) {
        case WEB_CHAT__SEND_MESSAGE:
          return ['payload', 'channelData'];
        case DIRECT_LINE__POST_ACTIVITY:
          return ['payload', 'activity', 'channelData'];
        default:
          return [];
      }
    };

    const getObjectEntries = urlParams => {
      if (!Object.fromEntries) {
        const params = {};
        for (const [key, value] of urlParams) {
          params[key] = value;
        }
        return params;
      }

      return Object.fromEntries(urlParams);
    };

    const getSessionStorageValue = key => {
      let val = window.sessionStorage.getItem(key);
      if (!val) return {};
      return JSON.parse(val);
    };

    const clientBotConfig = combinedConfig || {};

    window.CarLabs__addChannelDataPayload = (action: any, skipValidation = false) => {
      if (
        skipValidation ||
        [WEB_CHAT__SEND_MESSAGE, DIRECT_LINE__POST_ACTIVITY].includes(action.type)
      ) {
        const channelDataPath = getActionTypeChannelDataPath(action.type);
        const urlParams = getUrlParams();
        const paramsObj = urlParams ? getObjectEntries(urlParams) : {};
        const sessionStorageValue = clientBotConfig.sessionStorageAccess
          ? getSessionStorageValue(clientBotConfig.sessionStorageAccess)
          : {};
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'vendorId'],
          () => vendorUUID || 'default',
        );
        action = simpleUpdateIn(action, [...channelDataPath, 'urlParams'], () => paramsObj);
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'isInternalUsage'],
          () => !!scriptUsage,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'sessionStorageValue'],
          () => sessionStorageValue,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'corpId'],
          () => clientBotConfig.corpId,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'countryCode'],
          () => clientBotConfig.countryCode,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'vendorName'],
          () => clientBotConfig.label,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'vendorIcon'],
          () => clientBotConfig.styles.avatarImageUrl,
        );
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'hierarchyElementId'],
          () => clientBotConfig.hierarchyElementId,
        );
        action = simpleUpdateIn(action, [...channelDataPath, 'userIpAddress'], () => userIpAddress);
        action = simpleUpdateIn(action, [...channelDataPath, 'userAgent'], () => userAgent);
        action = simpleUpdateIn(action, [...channelDataPath, 'urlParams'], () => paramsObj);
        action = simpleUpdateIn(
          action,
          [...channelDataPath, 'sessionStorageValue'],
          () => sessionStorageValue,
        );

        if (action.type === DIRECT_LINE__POST_ACTIVITY) {
          // Brain postback uses the "text" property of an activity as the value
          // and the value as the text to be displayed to the users. Here, if we
          // see that the activity has a value and is object, we set the text and value.
          if (action.payload.activity.value && typeof action.payload.activity.value === 'object') {
            action.payload.activity.text = action.payload.activity.value.value;
            action.payload.activity.value = action.payload.activity.value.title;
          }
        }
      }
      return action;
    };

    ReactDOM.render(
      <App directlineToken={_directlineToken} clientBotConfig={clientBotConfig} />,
      document.getElementById(rootElementId),
    );
  };

  // Remove app from root element.
  const _destroy = (rootElementId: string) => {
    if (!rootElementId)
      return Logger('<CarLabsChat> Required root arguement missing from destroy...', true);

    const rootElement = document.getElementById(rootElementId);

    if (!rootElement)
      return Logger('<CarLabsChat> Root element not found, cannot destroy chat...', true);

    ReactDOM.unmountComponentAtNode(rootElement);
  };

  // Clear the chat history by removing messages in local storage.
  const _clearHistory = (): void => {
    const chatHistory = localStorage.getItem(LocalStorageKeys.ChatHistory);
    if (chatHistory) localStorage.removeItem(LocalStorageKeys.ChatHistory);
  };

  // If set to init on load, init to rootElementId from config. If a client
  // wishes to init the app themselves, their config in the DB
  // must have the initOnLoad property set to false so it doesn't
  // automatically init on load.
  if (_firestoreSettings.initOnLoad && !scriptUsage) _init(_firestoreSettings as IClientBotConfig);

  // Expose methods to client via window
  window.CarLabsChat = {
    init: _init,
    destroy: _destroy,
    clearHistory: _clearHistory,
    openBot: () => {
      window.postMessage('OPEN_BOT', window.origin);
    },
    closeBot: () => {
      window.postMessage('CLOSE_BOT', window.origin);
    },
    toggleBot: () => {
      window.postMessage('TOGGLE_BOT', window.origin);
    },
    setButtonClickBackCallBack: callback => {
      EventTrackingService.Instance.setButtonClickBackCallBack(callback);
    },
  };
})().then(() => EventTrackingService.Instance.emit({ name: 'BOT_STATE__LOADED' }));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
