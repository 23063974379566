import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import PostBackButton from '../PostBackButton';
import { IActivity } from '../../types';
import { range, messageIsFromBot } from '../../util';
import { DateTime } from 'luxon';
import {
  StyledDateInput,
  StyledCalenderWrapper,
  StyledDatePickerWrapper,
  StyledPostBackButtonWrapper,
  StyledIntroText,
} from './styles';
import TextMessage from '../TextMessage';

interface ICalendarProps {
  activity: IActivity;
}

const Calendar: React.FC<ICalendarProps> = ({ activity }) => {
  const [startDate, setStartDate] = useState<Date | null>(setHours(setMinutes(new Date(), 30), 16));
  const [calendarIsDisabled, setCalendarIsDisabled] = useState<boolean>(false);

  let startTime = 10;

  // Disable selecting past times for the current day
  if (startDate?.toDateString() === new Date().toDateString()) startTime = new Date().getHours();

  const endTime = 18;
  const allowedMinutes = [30, 60];

  const hoursRange = range(startTime, endTime);

  const includeTimes: Date[] = hoursRange.reduce((acc: Date[], hour: number) => {
    const hourMinutes = allowedMinutes.map(minute =>
      setHours(setMinutes(new Date(), minute), hour),
    );
    return [...acc, ...hourMinutes];
  }, []);

  const handleDateSelect = () => {
    // The date is sent back via the PostBackButton
    // This is used to disable the calendar and prevent
    // any changes to it after a postBack was sent.
    setCalendarIsDisabled(true);
  };

  return (
    <div style={{ marginBottom: 20 }}>
      {activity.text ? (
        <TextMessage text={activity.text} isBotMessage={messageIsFromBot(activity.from.role)} />
      ) : null}
      <StyledIntroText>Select Date & Time</StyledIntroText>
      <StyledCalenderWrapper className="StyledCalenderWrapper">
        <StyledDatePickerWrapper>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            includeTimes={includeTimes}
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={new Date()}
            disabled={calendarIsDisabled}
            customInput={
              <StyledDateInput
                readOnly
                value={`${startDate?.toLocaleString(undefined, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}`}
              />
            }
          />
        </StyledDatePickerWrapper>
        <StyledPostBackButtonWrapper>
          <div onClick={handleDateSelect}>
            <PostBackButton
              cardAction={{
                value: `${DateTime.fromISO(startDate?.toISOString()).toString()}`,
                title: 'Send',
              }}
            />
          </div>
        </StyledPostBackButtonWrapper>
      </StyledCalenderWrapper>
    </div>
  );
};

export default Calendar;
