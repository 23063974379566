import React, { useState, useContext, createContext } from 'react';

// Context
const WebchatStateContext = createContext<any>(null);

// Provider
export const WebchatStateProvider = ({ children }) => {
  const webchatState = useProvideWebchatState();
  return (
    <WebchatStateContext.Provider value={webchatState}>{children}</WebchatStateContext.Provider>
  );
};

// Hook thats exposed
export const useWebchatState = () => {
  return useContext(WebchatStateContext);
};

const useProvideWebchatState = () => {
  const [inputDisabled, setInputDisabled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');

  return {
    inputDisabled,
    setInputDisabled,
    menuOpen,
    setMenuOpen,
    conversationId,
    setConversationId,
  };
};
