import React from 'react';
import { ThemeProvider } from 'styled-components';
import { IThemeContext, IClientBotConfig } from '../types';
import { cloneDeep } from 'lodash';

interface IThemeProps {
  clientBotConfig: IClientBotConfig;
}

const Theme: React.FC<IThemeProps> = ({ children, clientBotConfig }) => {
  const theme: IThemeContext = cloneDeep(clientBotConfig.styles);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
