import React, { Fragment } from 'react';
import {
  carIcon as car,
  magnifyingGlassIcon as glass,
  supportHeadsetIcon as user,
  xmarkCrossIcon,
} from '../DropdownMenu/assets';

import {
  DropdownItemWrapper,
  DropdownItemTitle,
  WrapperImg,
  DropdownItemContent,
  MobileDropdownItemTitle,
  MobileWrapperImg,
  TitleOpen,
  Title,
  Link,
} from './styles';

import { IDropdownItem } from '../../types';

interface IDropdownItemProps {
  image: string;
  title: string;
  content: IDropdownItem[];
  isOpen: boolean;
  isMobile: boolean;
  toggled: boolean;
  onClick: Function;
}

const DropdownItem: React.FC<IDropdownItemProps> = ({
  image,
  title,
  content,
  isMobile,
  isOpen,
  toggled,
  onClick,
}) => {
  const icons = { car, glass, user };

  if (isMobile) {
    return (
      <Fragment>
        <DropdownItemWrapper>
          <MobileDropdownItemTitle toggled={toggled} onClick={onClick}>
            <MobileWrapperImg adjust={image === 'user'} src={icons[image]} alt="img" />
          </MobileDropdownItemTitle>
          {isOpen &&
            content.map((link, i) => (
              <DropdownItemContent key={i}>
                <Link href={link.link} target="_blank">
                  <span>{link.subtitle}</span>
                </Link>
              </DropdownItemContent>
            ))}
        </DropdownItemWrapper>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <DropdownItemWrapper>
        <DropdownItemTitle isOpen={isOpen} onClick={onClick}>
          {isOpen ? (
            <WrapperImg src={xmarkCrossIcon} alt="circle" />
          ) : (
            <WrapperImg adjust={image === 'user'} src={icons[image]} alt="img" />
          )}
          {isOpen ? <TitleOpen>{title}</TitleOpen> : <Title>{title}</Title>}
        </DropdownItemTitle>
        {isOpen &&
          content.map((link, i) => (
            <DropdownItemContent key={i}>
              <Link href={link.link} target="_blank">
                <span>{link.subtitle}</span>
              </Link>
            </DropdownItemContent>
          ))}
      </DropdownItemWrapper>
    </Fragment>
  );
};

export default DropdownItem;
