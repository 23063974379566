import styled from 'styled-components';

export const StyledSelect = styled.select`
  width: 50%;
  height: 44px;
  padding: 8px 12px;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-left: 25px;
`;
