import styled from 'styled-components';

export const StyledCompactListWrapper = styled.div`
  padding: 5px 20px;
  cursor: pointer;
`;

export const StyledListItem = styled.div`
  padding: 5px;
  border-left: 3px solid transparent;
  transition: border ease-out 0.3s;
  &:hover {
    border-left: 3px solid rgba(0, 0, 0, 0.2);
  }
  border-bottom: ${props => (props.compare ? '0' : '1px solid #ddd')};
  ${props =>
    props.select
      ? `background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjY2NjY2NjIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAwIDEwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I2NjY2NjYztzdHJva2Utd2lkdGg6NDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9Cjwvc3R5bGU+PHBhdGggZD0iTTE1LjUsMzguOCI+PC9wYXRoPjxjaXJjbGUgY2xhc3M9InN0MCIgY3g9IjUwLjQiIGN5PSI1MCIgcj0iMzkiPjwvY2lyY2xlPjwvc3ZnPg==")
    no-repeat center left 4px;
    background-size: 20px;
  `
      : null}
  ${props =>
    props.select && props.selected
      ? `background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjNDRDQzAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgOTAgOTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDkwIDkwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBkPSJNNDUsMi41QzIxLjUyOCwyLjUsMi41LDIxLjUyOCwyLjUsNDVTMjEuNTI4LDg3LjUsNDUsODcuNVM4Ny41LDY4LjQ3Miw4Ny41LDQ1UzY4LjQ3MiwyLjUsNDUsMi41eiBNNzAuMjg1LDM1LjE1MSAgTDQzLjUxNyw2MS45MmMtMC45MzgsMC45MzgtMi4yMDksMS40NjUtMy41MzYsMS40NjVzLTIuNTk4LTAuNTI2LTMuNTM2LTEuNDY1bC0xNi43MzEtMTYuNzNjLTEuOTUzLTEuOTUzLTEuOTUzLTUuMTE5LDAtNy4wNzEgIGMxLjk1My0xLjk1Miw1LjExOC0xLjk1Miw3LjA3MSwwbDEzLjE5NSwxMy4xOTVMNjMuMjE1LDI4LjA4YzEuOTUxLTEuOTUyLDUuMTE5LTEuOTUyLDcuMDcsMCAgQzcyLjIzOCwzMC4wMzIsNzIuMjM4LDMzLjE5OCw3MC4yODUsMzUuMTUxeiI+PC9wYXRoPjwvc3ZnPg==")
    no-repeat center left 4px;
    background-size: 20px;
    `
      : null}
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
`;

export const StyledBar = styled.div`
  width: 100%;
  position: relative;
  height: 5px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: ${props => (props.compare ? 'block' : 'none')};
`;

export const StyledBarFill = styled.div`
  width: ${props => (props.width ? `${props.width}%` : '0')};
  height: 5px;
  border-radius: 10px;
  background: #75a9de;
  transition: all ease-in-out 1s;
`;

export const StyledListValue = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #585858;
  text-align: left;
  padding-left: 10px;
`;

export const StyledImageWrapper = styled.div`
  ${props => (props.select ? 'padding-left: 25px;' : null)};
`;

export const StyledImage = styled.img`
  width: 150px;
`;

export const StyledTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #000;
`;

export const StyledSubtitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #555;
`;

export const StyledText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #666;
`;

export const StyledShowMoreButton = styled.button`
  -webkit-appearance: none;
  background: #fff;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 2px;
  outline: 0;
  padding: 8px 12px;
  border: 1px solid #0078d7;
  border-radius: 5px;
  text-align: center;
  color: #0078d7;
  font-size: 14px;
  transition: opacity ease-out 0.4s;
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
