import React, { Fragment, useContext, useState } from 'react';
import { BotTeaser, IThemeContext } from '../../types';
import { ThemeContext } from 'styled-components';
import {
  NonInteractiveTeaserText,
  TeaserActionButton,
  TeaserBody,
  TeaserCloseButton,
  TeaserContainer,
  TeaserFooter,
  TeaserFooterAvatar,
  TeaserFooterClose,
  TeaserFooterInput,
  TeaserMessageInput,
  TeaserQRBContent,
  TeaserQrbs,
  TeaserStyledAvatar,
  TeaserStyledText,
  TeaserStyledTextWrapper,
  TeaserTextMessages,
  TeaserTextMessagesContent,
} from './styles';
import { StyledAvatarImage, StyledAvatarShadow } from '../WebChat/styles';

interface IChatTeaserConfig {
  isOpen: boolean;
  toggleFunction: Function;
}

const TeaserChat: React.FC<IChatTeaserConfig> = props => {
  const themeContext: IThemeContext = useContext(ThemeContext);
  const teasers = themeContext.teaserButtons;
  const textTeasers = themeContext.teaserText;
  const [showTeaser, setShowTeaser] = useState(true);

  const handleKeyDown = event => {
    // send message and show chat when user presses enter key
    if (event.key === 'Enter') {
      if (event.target.value !== '') {
        props.toggleFunction(event.target.value, true);
      }
      return false;
    }
    return true;
  };

  const onAvatarClick = () => {
    // show chat window without sending any message
    props.toggleFunction(null, true);
  };

  const onClose = () => {
    // show chat window without sending any message
    setShowTeaser(false);
  };

  if (!themeContext.interactiveTeaser) {
    return (
      <div>
        {/* Yes This is a div and should not be a fragment */}
        {themeContext.nonInteractiveTeaserText && (
          <NonInteractiveTeaserText className="NonInteractiveTeaserText1">
            {themeContext.nonInteractiveTeaserText}
          </NonInteractiveTeaserText>
        )}
        <TeaserFooterAvatar className="footer-avatar1">
          <TeaserStyledAvatar className="TeaserStyledAvatar1" onClick={onAvatarClick}>
            <StyledAvatarImage className="StyledAvatarImage1" />
            <StyledAvatarShadow className="StyledAvatarShadow1" />
          </TeaserStyledAvatar>
        </TeaserFooterAvatar>
      </div>
    );
  }

  return (
    <Fragment>
      <TeaserBody className="TeaserBody">
        <TeaserContainer className="container">
          <TeaserTextMessages
            className="text-msgs"
            style={{ display: showTeaser ? 'flex' : 'none' }}
          >
            <TeaserFooterAvatar className="message-margin" />
            <TeaserTextMessagesContent className="message-content">
              {textTeasers.map((text, indx) => {
                return (
                  <TeaserStyledTextWrapper key={`teaser-text-${indx}`} isBotMessage={true}>
                    <TeaserStyledText
                      isLast={indx !== 0 && indx === textTeasers.length - 1}
                      isFirst={indx === 0}
                      isBotMessage={true}
                      className="message"
                    >
                      {text}
                    </TeaserStyledText>
                  </TeaserStyledTextWrapper>
                );
              })}
            </TeaserTextMessagesContent>
            <TeaserFooterAvatar className="message-margin" />
          </TeaserTextMessages>

          <TeaserQrbs className="qrbs" style={{ display: showTeaser ? 'flex' : 'none' }}>
            <TeaserFooterAvatar className="message-margin" />
            <TeaserQRBContent className="qrb-content">
              {teasers.map((teaser, index) => {
                return (
                  <TeaserActionButton
                    key={`teaser-button-${index}`}
                    onClick={() => props.toggleFunction(getTeaserPostBackMessage(teaser), true)}
                    type="button"
                  >
                    {teaser.title}
                  </TeaserActionButton>
                );
              })}
            </TeaserQRBContent>
            <TeaserFooterAvatar className="message-margin" />
          </TeaserQrbs>
          <TeaserFooter className="item webchat-teaser-footer">
            <TeaserFooterClose
              className="item footer-close"
              style={{ display: showTeaser ? 'inline-block' : 'none' }}
            >
              <TeaserCloseButton onClick={onClose} className="close-button" type="button">
                X
              </TeaserCloseButton>
            </TeaserFooterClose>
            <TeaserFooterInput
              className="item footer-input"
              style={{ display: showTeaser ? 'flex' : 'none' }}
            >
              <TeaserMessageInput
                tabindex="0"
                onKeyDown={handleKeyDown}
                className="message-input"
                placeholder={themeContext.inputBoxPlaceholderText}
              />
            </TeaserFooterInput>
            <TeaserFooterAvatar className="footer-avatar2">
              <TeaserStyledAvatar className="StyledAvatar2" onClick={onAvatarClick}>
                <StyledAvatarImage className="StyledAvatarImage2" />
                <StyledAvatarShadow className="StyledAvatarShadow2" />
              </TeaserStyledAvatar>
            </TeaserFooterAvatar>
          </TeaserFooter>
        </TeaserContainer>
      </TeaserBody>
    </Fragment>
  );
};

const getTeaserPostBackMessage = (teaser: BotTeaser) => {
  if (teaser.type === 'button') {
    if (teaser.nodeId) {
      return { title: teaser.title, value: `__setnode__ ${teaser.nodeId}` };
    } else {
      if (teaser.value) {
        return { title: teaser.title, value: teaser.value };
      } else {
        return teaser.title;
      }
    }
  } else if (teaser.type === 'text') {
    if (teaser.value) {
      return { title: teaser.title, value: teaser.value };
    } else {
      return teaser.title;
    }
  }
};

export default TeaserChat;
