import React, { Fragment } from 'react';
import PostBackButton from '../PostBackButton';
import { IActivity } from '../../types';
import TextMessage from '../TextMessage';
import { messageIsFromBot } from '../../util';

interface IListProps {
  activity: IActivity;
}

const List: React.FC<IListProps> = ({ activity }) => {
  const attachmentRenderer = activity?.attachments.map((attachment, index) => {
    const { content } = attachment;
    return (
      <Fragment key={index}>
        {content.text ? (
          <TextMessage text={content.text} isBotMessage={messageIsFromBot(activity.from.role)} />
        ) : null}
        {content.images?.length
          ? content.images.map((imageData, index) => (
              <img key={index} style={{ maxWidth: '100%' }} src={imageData.url} alt="Attachment" />
            ))
          : null}
        {content.buttons?.length
          ? content.buttons.map((buttonData, index) => (
              <div className="button-wrapper" key={index}>
                <PostBackButton cardAction={buttonData} />
              </div>
            ))
          : null}
      </Fragment>
    );
  });

  return (
    <>
      {activity && activity.text ? (
        <TextMessage text={activity.text} isBotMessage={messageIsFromBot(activity.from.role)} />
      ) : null}
      {attachmentRenderer}
    </>
  );
};

export default List;
