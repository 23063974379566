export interface IClientBotConfig {
  rootElementId: string;
  initOnLoad: boolean;
  countryCode: string;
  corpId: string;
  clearPreviousSessionOnInit: boolean;
  id: string;
  initNodeId?: string;
  eventTriggers: EventTriggers[];
  scriptInjectionSource: string;
  debugMode: boolean;
  label: string;
  hierarchyElementId: number;
  styles: IThemeContext;
  storeMessages: boolean;
  // enableFullScreenOnMobile: boolean;
  introMessages?: string[];
  // introSuggestedActions?: ICardAction[];
  introVideo?: string;
}

export interface ICarLabsChat {
  init: (clientBotConfig: IClientBotConfig) => void;
  destroy: (root: string) => void;
  clearHistory: () => void;
  openBot: () => void;
  closeBot: () => void;
  toggleBot: () => void;
  setButtonClickBackCallBack: (callback: (payload: ButtonClickedEventPayload) => void) => void;
}

export type TriggerNode = {
  id: string;
};

export type EventTriggers = {
  eventTriggerId: string;
  eventTriggerType: string;
  eventTriggerInput: string;
  eventTriggerNode: TriggerNode;
};

export enum ChatPositions {
  Right = 'right',
  Left = 'left',
}

export enum AvatarAnimations {
  Bounce = 'bounce',
  Static = 'static',
}
export interface IDropdownStyle {
  avatarSize: number;
  avatarSizeMobile: number;
  avatarLeft: number;
  avatarLeftMobile: number;
  avatarTop: number;
  avatarTopMobile: number;
  height: number; // percentage
  heightMobile: number; // percentage
  heightMenuMobile: number; // percentage
}
export interface IDropdownItem {
  subtitle: string;
  link: string;
}
export interface IDropdownMenu {
  id: number;
  title: string;
  image: string;
  content: IDropdownItem[];
}
export interface BotTeaser {
  type: 'button' | 'text';
  title: string;
  value: string;
  nodeId: string;
  data: object;
}
export interface BotTeaserStyle {
  backgroundImageUrl: string;
  backgroundColor: string;
  textFontSize: number;
  buttonBorderRadius: number;
  buttonBackgroundColor: string;
  showBackground: boolean;
}
export interface IThemeContext {
  headerText: string;
  headerSize: number;
  headerTextColor: string;
  headerTextSize: number;
  headerTextFontFamily: string;
  headerBackgroundColor: string;
  closeButtonSize: number;

  chatContainerWidth: number;
  chatContainerHeight: number;
  chatContainerBorderRadius: number;
  chatContainerBackgroundImageUrl: string;
  chatContainerBackgroundColor: string;

  textBubbleRadius: number;
  distanceBetweenBubbles: number;
  textFontSize: number;
  textFontFamily: string;
  textUserColor: string;
  textBotColor: string;
  textUserBackgroundColor: string;
  textBotBackgroundColor: string;

  suggestedActionsBackgroundColor: string;
  suggestedActionsBorder: string;
  suggestedActionsHeight: number;
  actionButtonsDistance: number;
  actionButtonBorder: string;
  actionButtonBorderRadius: number;
  actionButtonColor: string;
  actionButtonBackgroundColor: string;
  actionButtonFontSize: number;
  actionButtonFontWeight: number;
  actionButtonFontFamily: string;
  suggestedActionsArrowWidth: number;
  suggestedActionsArrowBorder: string;
  suggestedActionsArrowBackgroundColor: string;
  suggestedActionsArrowSize: number;
  suggestedActionsArrowImageUrlLeft: string;
  suggestedActionsArrowImageUrlRight: string;

  inputBoxPlaceholderText: string;
  inputHeight: number;
  inputBorder: string;
  showHamburger: boolean;
  sendImageSize: number;

  avatarSize: number; // percentage
  showAvatar: boolean;
  avatarImageUrl: string;
  avatarAnimation: AvatarAnimations;

  mobileHeight: number; // percentage

  teaserText: string[];
  nonInteractiveTeaserText: string;
  chatPosition: ChatPositions;
  fixedPosition: boolean;
  teaserButtons: BotTeaser[];
  openBotOnInit: boolean;
  showTeaser: boolean;
  interactiveTeaser: boolean;
  teaserStyle: BotTeaserStyle;

  showDropdownMenu: boolean;
  dropdownButtons: IDropdownMenu[];
  dropdownStyle: IDropdownStyle;
}

interface IBaseBotEvent {
  name: string;
  payload?: object;
}

interface IBotInitEvent extends IBaseBotEvent {
  name: 'BOT_STATE__INIT';
}

interface IBotLoadedEvent extends IBaseBotEvent {
  name: 'BOT_STATE__LOADED';
}

interface IBotOpenedEvent extends IBaseBotEvent {
  name: 'BOT_STATE__OPENED';
}

interface IBotClosedEvent extends IBaseBotEvent {
  name: 'BOT_STATE__CLOSED';
}

interface IContactMeMenuAction extends IBaseBotEvent {
  name: 'MENU_ACTION__CONTACT_ME';
}

interface IStartOverMenuAction extends IBaseBotEvent {
  name: 'MENU_ACTION__START_OVER';
}

interface IOpenedPersistentMenu extends IBaseBotEvent {
  name: 'PERSISTENT_MENU__OPEN';
}

interface IClosedPersistentMenu extends IBaseBotEvent {
  name: 'PERSISTENT_MENU__CLOSED';
}

export interface ButtonClickedEventPayload {
  title: string;
  value: string;
  type: 'POST_BACK' | 'MESSAGE_BACK' | 'IM_BACK';
}

interface IButtonClicked extends IBaseBotEvent {
  name: 'BOT_ACTION__BUTTON_CLICKED';
  payload: ButtonClickedEventPayload;
}

interface ICarouselNavigation extends IBaseBotEvent {
  name: 'CAROUSEL_NAVIGATION';
  payload: {
    direction: 'LEFT' | 'RIGHT';
  };
}

export type IBotEvents =
  | IBotInitEvent
  | IBotLoadedEvent
  | IBotOpenedEvent
  | IBotClosedEvent
  | IContactMeMenuAction
  | IStartOverMenuAction
  | IOpenedPersistentMenu
  | IClosedPersistentMenu
  | IButtonClicked
  | ICarouselNavigation;

export enum LocalStorageKeys {
  ChatHistory = 'cl-messages',
}

export enum MessageTypes {
  Message = 'message',
  PostBack = 'postBack',
}

export enum EmbedScript {
  ScriptId = 'carlabs-bot-script',
  ScriptUUID = 'data-bot-id',
  ScriptUsage = 'data-script-usage',
}

export enum AttachmentLayoutTypes {
  Carousel = 'carousel',
  List = 'list',
  CompactList = 'compact_list',
  Calendar = 'calendar',
  SingleItemDropDown = 'single_item_drop_down',
  Javascript = 'javascript',
  GetSessionStorage = 'get_session_storage',
}

export enum Environments {
  Prod = 'prod',
  Staging = 'staging',
  Dev = 'dev',
}

export enum AwsRegionEnum {
  US_EAST_1 = 'us-east-1',
  AP_SOUTH_1 = 'ap-south-1',
}

export enum RootDomElementId {
  CarlabsRootElement = 'carlabs-root-element',
}

export enum CardActionTypes {
  PostBack = 'postBack',
  GetUserLocation = 'getUserLocation',
  OpenUrl = 'openUrl',
}

export interface ICardAction {
  type?: CardActionTypes;
  title: string;
  value: string;
  displayText?: string;
  text?: string;
}

export enum ActivityTypes {
  Message = 'message',
}

export interface IAttachmentContent {
  title?: string;
  subtitle?: string;
  images: Array<{ url: string }>;
  buttons?: ICardAction[];
  text?: string;
  isSelected?: boolean;
  barValue?: number;
}

export interface IAttachment {
  content: IAttachmentContent;
  contentUrl?: string;
  contentType?:
    | 'image/gif'
    | 'image/jpeg'
    | 'image/png'
    | 'image/svg'
    | 'image/svg+xml'
    | 'text/markdown'
    | 'text/plain';
  name?: string;
  value?: string;
}

export enum ActivityRole {
  Bot = 'bot',
  User = 'user',
}

export enum ActivityTextFormat {
  MarkDown = 'markdown',
  Plain = 'plain',
}

export interface IActivity {
  id: string | number; // not sure
  type: ActivityTypes;
  text: string;
  value: string;
  channelData: any; // Could change, keep to any
  from: {
    role: ActivityRole;
  };
  suggestedActions: ICardAction[][];
  timestamp?: string;
  textFormat: ActivityTextFormat;
  attachmentLayout: AttachmentLayoutTypes;
  attachments: IAttachment[];
}
