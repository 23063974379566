import React, { Fragment } from 'react';
import SuggestedActionsScrollList from './SuggestedActionsScrollList';
import { ICardAction } from '../../types';

interface ISuggestedActionsProps {
  suggestedActions: ICardAction[][];
}

const SuggestedActions: React.FC<ISuggestedActionsProps> = ({
  suggestedActions,
}: ISuggestedActionsProps): JSX.Element | null => {
  return !!suggestedActions.length ? (
    <Fragment>
      {suggestedActions.map((cardAction: ICardAction[], index: number) => {
        // Because of the way we have our data structured, we have to
        // loop twice. The frst index of the suggestedAction contains
        // an Array of suggested actions which we need to pass to the
        // CardActionButton component.
        if (Array.isArray(cardAction) && cardAction.length) {
          return <SuggestedActionsScrollList key={index} cardActions={cardAction} />;
        }
        return null;
      })}
    </Fragment>
  ) : null;
};

export default SuggestedActions;
