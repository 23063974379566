import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledChatHeaderWrapper, StyledChatHeader, StyledCloseButton } from './styles';
import { IThemeContext } from '../../types';

const ChatHeader = ({ onClick }) => {
  const themeContext: IThemeContext = useContext(ThemeContext);

  return (
    <StyledChatHeaderWrapper className="StyledChatHeaderWrapper">
      <StyledChatHeader>{themeContext.headerText}</StyledChatHeader>
      <StyledCloseButton onClick={onClick}>+</StyledCloseButton>
    </StyledChatHeaderWrapper>
  );
};

export default ChatHeader;
