import React, { useContext, useRef, useCallback, useState } from 'react';
import { hooks } from 'botframework-webchat-component';
import { ThemeContext } from 'styled-components';
import { useWebchatState } from '../../hooks/webchatstate.hooks';
import { useComponentClickListener } from '../../hooks/clicklistener.hooks';
import {
  StyledForm,
  StyledInput,
  StyledSendIcon,
  StyledPersistentMenu,
  StyledPersistentMenuPopover,
  StyledPersistentMenuLink,
} from './styles';
import { IThemeContext } from '../../types';
import { EventTrackingService } from '../../services/events-tracking';

const { useSendPostBack, useSendMessage } = hooks;

interface IInputBoxProps {}

const InputBox: React.FC<IInputBoxProps> = () => {
  const themeContext: IThemeContext = useContext(ThemeContext);
  const webchatState = useWebchatState();
  const sendMessage = useSendMessage();
  const sendPostBack = useSendPostBack();
  const clickListenerRef = useRef(null);
  const [sendBoxValue, setSendBoxValue] = useState('');

  const handleChange = useCallback(
    ({ target: { value } }): void => {
      setSendBoxValue(value);
    },
    [setSendBoxValue],
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    sendMessage(sendBoxValue);
    setSendBoxValue('');
  };

  useComponentClickListener(clickListenerRef, 'outside');

  const handleMenuClick = () => {
    const newMenuOpenState = !webchatState.menuOpen;
    EventTrackingService.Instance.emit({
      name: newMenuOpenState ? 'PERSISTENT_MENU__OPEN' : 'PERSISTENT_MENU__CLOSED',
    });
    webchatState.setMenuOpen(newMenuOpenState);
  };

  const handleStartOver = () => {
    EventTrackingService.Instance.emit({
      name: 'MENU_ACTION__START_OVER',
    });
    sendPostBack('Start Over');
  };

  const handleContactMe = () => {
    EventTrackingService.Instance.emit({
      name: 'MENU_ACTION__CONTACT_ME',
    });
    // send a phrase to trigger prompt.handoff
    sendPostBack({ value: 'contact me', title: 'Contact Me' });
  };

  return (
    <StyledForm className="StyledForm" onSubmit={handleSubmit}>
      <StyledPersistentMenu
        onClick={handleMenuClick}
        ref={clickListenerRef}
        className="StyledPersistentMenu"
      >
        <StyledPersistentMenuPopover isOpen={webchatState.menuOpen}>
          <StyledPersistentMenuLink onClick={handleStartOver}>Start Over</StyledPersistentMenuLink>
          <StyledPersistentMenuLink onClick={handleContactMe}>Contact Me</StyledPersistentMenuLink>
        </StyledPersistentMenuPopover>
      </StyledPersistentMenu>
      <StyledInput
        className="styledInput"
        disabled={webchatState.inputDisabled}
        autoFocus={true}
        onChange={handleChange}
        type="textbox"
        value={sendBoxValue}
        placeholder={themeContext.inputBoxPlaceholderText}
      />
      {webchatState.inputDisabled ? null : <StyledSendIcon onClick={handleSubmit} />}
    </StyledForm>
  );
};

export default InputBox;
