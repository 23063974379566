import { hooks } from 'botframework-webchat-component';
import React, { useCallback, useState } from 'react';
import has from 'lodash.has';
import { isValidURL, isValidJSONString } from '../../util';
import { StyledActionButton } from './styles';
import { ICardAction, CardActionTypes } from '../../types';
import { EventTrackingService } from '../../services/events-tracking';
import { useWebchatState } from '../../hooks/webchatstate.hooks';
import { Config } from '../../util/config';

const { useSendPostBack, usePostActivity } = hooks;

const stringifyAndEncode = (payload: Record<string, unknown>): string =>
  encodeURIComponent(JSON.stringify(payload));

interface IPostBackButtonProps {
  cardAction: ICardAction;
}

type GeolocationPosition = {
  coords: {
    latitude: number;
    longitude: number;
  };
  timestamp: string;
};

const PostBackButton = ({ cardAction }: IPostBackButtonProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendPostBack = useSendPostBack();
  const postActivity = usePostActivity();
  const webchatState = useWebchatState();

  const getPosition = (): Promise<GeolocationPosition> => {
    return new Promise((res: any, rej: any) => {
      navigator.geolocation.getCurrentPosition(res, rej);
    });
  };

  const handleClick = useCallback(async () => {
    // The value received in props can either be a action (localResponse)
    // or it can be a URL in which case we link out. We check to see if
    // the value is URL and if it is we link the users out.
    if (has(window, 'buttonClickCallback')) {
      window['buttonClickCallBack'](cardAction.value);
    }
    EventTrackingService.Instance.emit({
      name: 'BOT_ACTION__BUTTON_CLICKED',
      payload: {
        title: cardAction.title,
        value: cardAction.value,
        type: 'POST_BACK',
      },
    });

    if (cardAction.type === CardActionTypes.OpenUrl) {
      window.open(encodeURI(cardAction.value));
      return;
    }
    // Redundant, until we have template UI in place to force
    // people to add type=openUrl we also double check
    // if they passed in a URL and open it if they did.
    if (isValidURL(cardAction.value)) {
      window.open(cardAction.value);
      return;
    }

    if (cardAction.type === CardActionTypes.GetUserLocation) {
      setButtonDisabled(true);
      cardAction.title = 'Loading';
      const position = await getPosition();
      const { coords } = position;
      postActivity({
        type: 'message',
        text: `${coords.latitude},${coords.longitude}`,
        value: cardAction.value,
      });
      return;
    }

    // Handle LocalResponse Buttons
    if (isValidJSONString(cardAction.value)) {
      const localResponse = JSON.parse(cardAction.value).localResponse;

      if (isValidURL(localResponse?.result?.parameters?.externalUrl)) {
        const { externalUrl, kpi } = localResponse.result.parameters;
        if (kpi) {
          const platformPayLoad = window.CarLabs__addChannelDataPayload({}, true);
          const conversationId = webchatState.conversationId;
          window.open(
            `${Config.getShopBotUrl()}/redirect-v2?url=${encodeURIComponent(
              externalUrl,
            )}&conversationId=${conversationId}&kpi=${stringifyAndEncode(
              kpi,
            )}&platformPayload=${stringifyAndEncode(platformPayLoad)}`,
          );
        }
        window.open(externalUrl);
        return;
      }
    }

    // We disable buttons after they've been clicked so a user
    // can't go back and re-send the previously sent data.
    setButtonDisabled(true);
    sendPostBack({ value: cardAction.value, title: cardAction.title }); // Send value to botframework
  }, [cardAction, sendPostBack, postActivity, webchatState]);

  return (
    <StyledActionButton
      disabled={buttonDisabled}
      onClick={handleClick}
      type="button"
      className="StyledActionButton"
    >
      {cardAction.title}
    </StyledActionButton>
  );
};

export default PostBackButton;
