import { Environments, AwsRegionEnum } from '../types';

interface IConfigBag {
  shopBotUrl: string;
  sentryDsn: string;
  directlineToken: string | Record<AwsRegionEnum, string>;
  firebaseConfig: Record<string, string>;
}

const ConfigBag: Record<Environments, IConfigBag> = {
  [Environments.Dev]: {
    shopBotUrl: 'https://shopbot.dev.car-labs.com',
    sentryDsn: 'https://3685b33bc10d45b384a1498bcecebda3@sentry.io/1871291',
    directlineToken: '5t3un5S8EPg.cwA.Sic.wsVofo1Je4mfpXeYPTljv8Cs3yJVWQkI89-MONoHKic',
    firebaseConfig: {
      apiKey: 'AIzaSyAmwelCnYp0gOVOeHYL7BpIeDKKInRbMCA',
      authDomain: 'brain-ui-v1-dev.firebaseapp.com',
      databaseURL: 'https://brain-ui-v1-dev.firebaseio.com',
      projectId: 'brain-ui-v1-dev',
      messagingSenderId: '177764226367',
      appId: '1:177764226367:web:0894bc9cb5ab0c575fef8a',
      measurementId: 'G-9GR7LVPN4P',
    },
  },
  [Environments.Staging]: {
    shopBotUrl: 'https://shopbot.staging.car-labs.com',
    sentryDsn: 'https://7d3ab3efe94243e8a9bcb039e65bd2ff@sentry.io/1871375',
    directlineToken: 'qe_qIwpBV1U.J3QVmA3WARnYFb8nWm_Uhv-LFyyReH-kQLJD4ZEPeKA',
    firebaseConfig: {
      apiKey: 'AIzaSyDXkg7ZRDFqWU24dPXXyKyOVLETFEAxTPI',
      authDomain: 'brain-ui-v1-staging.firebaseapp.com',
      databaseURL: 'https://brain-ui-v1-staging.firebaseio.com',
      projectId: 'brain-ui-v1-staging',
      storageBucket: 'brain-ui-v1-staging.appspot.com',
      messagingSenderId: '103271147830',
      appId: '1:103271147830:web:86bd760c675fd8fb4f6b97',
      measurementId: 'G-7H8X09HJ0L',
    },
  },
  [Environments.Prod]: {
    shopBotUrl: 'https://shopbot.carlabs.com',
    sentryDsn: 'https://43d8cacd82c1485190a3a00357db3ce0@sentry.io/1871377',
    directlineToken: {
      [AwsRegionEnum.US_EAST_1]: 'fzYt166JL5k.cwA.RjE.8UUIF81ChAHPdYDizVCPeldvKbqk0clyG9ShNKEnIKc',
      [AwsRegionEnum.AP_SOUTH_1]: 'VyyGdfOB5bo.w03-59DubIp5azrjsiFXZBuPnAhqlVSxMWDYRIo5h8k',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyCqVGgeVYjhEy7L4Km0yifwDOJY103nsjU',
      authDomain: 'brain-ui-v1.firebaseapp.com',
      databaseURL: 'https://brain-ui-v1.firebaseio.com',
      projectId: 'brain-ui-v1',
      storageBucket: 'brain-ui-v1.appspot.com',
      messagingSenderId: '92363743524',
      appId: '1:92363743524:web:0add830d7fdf2882c393b1',
      measurementId: 'G-0GBBPJYJK1',
    },
  },
};

export class Config {
  static getEnvironment() {
    return (process.env.REACT_APP_ENV as Environments) || Environments.Dev;
  }

  static getShopBotUrl = (): string => {
    return Config.getEnvironmentBag('shopBotUrl') as string;
  };

  static getFirebaseConfig = (): Record<string, string> => {
    return Config.getEnvironmentBag('firebaseConfig') as Record<string, string>;
  };

  static getSentryDsn = (): string => {
    return Config.getEnvironmentBag('sentryDsn') as string;
  };

  static getDirectlineToken = (botAwsRegion: string): string => {
    const token = Config.getEnvironmentBag('directlineToken');
    if (typeof token === 'string') {
      return token;
    }
    return token[botAwsRegion];
  };

  static getEnvironmentBag = (key: keyof IConfigBag) => {
    const configBag = ConfigBag[Config.getEnvironment()];
    if (!configBag) {
      throw new Error("Config doesn't exist for environment");
    }
    return configBag[key];
  };
}
