import React, { Fragment, useState, useEffect, useContext } from 'react';
import DropdownItem from '../DropdownItem';
import { IThemeContext } from '../../types';
import { ThemeContext } from 'styled-components';
import { Wrapper, MobileWrapper, StyledRobot, DropdownMenuWrapper } from './styles';
import { xmarkCrossIcon } from './assets';
import {
  DropdownItemWrapper,
  MobileWrapperImg,
  MobileDropdownItemTitle,
} from '../DropdownItem/styles';

const DropdownMenu = () => {
  const themeContext: IThemeContext = useContext(ThemeContext);
  const dropdownButtons = themeContext.dropdownButtons;
  const dropdownStyle = themeContext.dropdownStyle;

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 576;

  const [openedDropdown, setOpenedDropdown] = useState(-1);
  const handleMenuClick = id => {
    setOpenedDropdown(openedDropdown === id ? -1 : id);
  };

  const [openedMenu, setOpenedMenu] = useState(false);
  const handleMenuClose = () => {
    setOpenedMenu(!openedMenu);
    setOpenedDropdown(-1);
  };
  const handleRobotClick = () => {
    if (!openedMenu) {
      handleMenuClose();
    }
  };

  const menuToggled = openedDropdown !== -1;

  if (isMobile) {
    return (
      <Fragment>
        <MobileWrapper>
          <StyledRobot animate={openedMenu} onClick={handleRobotClick} />
        </MobileWrapper>
        <MobileWrapper
          style={{
            bottom: dropdownStyle.heightMenuMobile ? `${dropdownStyle.heightMenuMobile}%` : '25%',
          }}
        >
          <DropdownMenuWrapper animate={openedMenu}>
            {dropdownButtons.map(({ id, image, title, content }) => (
              <DropdownItem
                key={id}
                image={image}
                title={title}
                content={content}
                isOpen={openedDropdown === id}
                isMobile={isMobile}
                toggled={menuToggled}
                onClick={() => handleMenuClick(id)}
              />
            ))}
            <DropdownItemWrapper>
              <MobileDropdownItemTitle toggled={menuToggled} onClick={() => handleMenuClose()}>
                <MobileWrapperImg src={xmarkCrossIcon} alt="circle" />
              </MobileDropdownItemTitle>
            </DropdownItemWrapper>
          </DropdownMenuWrapper>
        </MobileWrapper>
      </Fragment>
    );
  }
  return (
    <Wrapper>
      <StyledRobot />
      <DropdownMenuWrapper>
        {dropdownButtons.map(({ id, image, title, content }) => (
          <DropdownItem
            key={id}
            image={image}
            title={title}
            content={content}
            isOpen={openedDropdown === id}
            isMobile={isMobile}
            toggled={menuToggled}
            onClick={() => handleMenuClick(id)}
          />
        ))}
      </DropdownMenuWrapper>
    </Wrapper>
  );
};

export default DropdownMenu;
