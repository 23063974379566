import styled from 'styled-components';

export const DropdownItemWrapper = styled.div`
  float: left;
  clear: both;
  margin: 1px;
  top: 10px;
`;

export const WrapperImg = styled.img`
  display: inline-block;
  position: relative;
  top: 12px;
  left: ${props => (props.adjust ? '12px' : '12px')};
  width: 24px;
  height 24px;
  overflow: hidden;
  background: #ffffff;
  z-index: 2;
  cursor: pointer;
`;

export const MobileWrapperImg = styled.img`
  display: inline-block;
  position: relative;
  top: 12px;
  left: ${props => (props.adjust ? '12px' : '12px')};
  width: 24px;
  height 24px;
  overflow: hidden;
  background: #ffffff;
  z-index: 2;
`;

export const Title = styled.div`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  color: transparent;
  cursor: pointer;
`;

export const TitleOpen = styled.div`
  color: #1f2c35;
  position: absolute;
  left: 25%;
  top: 32%;
`;

export const DropdownItemTitle = styled.div`
  position: relative;
  display: inline-block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 27px;
  box-shadow: 0px 3px 6px #00000029;
  color: ${props => (props.isOpen ? '#1F2C35' : '')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  height: 48px;
  width: ${props => (props.isOpen ? '202px' : '48px')};
  opacity: 1;
  overflow: hidden;
  transition: width 0.3s ease-in-out;

  ${Title} {
    opacity: 0;
  }

  &:hover {
    width: 202px;
    color: #1f2c35;
    cursor: pointer;

    ${Title} {
      color: #1f2c35;
      display: inline-block;
      text-indent: 0;
      cursor: pointer;
      position: absolute;
      left: 25%;
      top: 32%;
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  }
`;

export const MobileDropdownItemTitle = styled.div`
  position: relative;
  left: ${props => (props.toggled ? '154px' : '0px')};
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 27px;
  box-shadow: 0px 3px 6px #00000029;
  color: #1f2c35;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0px;
  height: 48px;
  width: 48px;
  opacity: 1;
  overflow: hidden;
  margin: 1px;
  &:hover {
    cursor: pointer;
  }
`;

export const DropdownItemContent = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 0.8;
  box-shadow: 0px 3px 6px #00000029;
  width: 202px;
  height: 29px;
  text-align: left;
  margin: 1px;
  &:hover {
    opacity: 1;
    color: #ea0029;
    cursor: pointer;
  }
`;

export const Link = styled.a`
  span {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #37434d;
    display: flex;
    justify-content: left;
    margin-left: 10%;
    margin-top: 2px;
    position: relative;
    top: 9px;
    &:hover {
      color: #ea0029;
      cursor: pointer;
    }
  }
  text-decoration: none;
  top: 10px;
`;
