import styled from 'styled-components';

export const StyledActionButton = styled.button`
  -webkit-appearance: none;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  width: 100%;
  height: ${props => props.theme.suggestedActionsHeight - 2}px;
  outline: 0;
  padding: 8px 12px;
  background: ${props => props.theme.actionButtonBackgroundColor};
  color: ${props => props.theme.actionButtonColor};
  font-weight: ${props => props.theme.actionButtonFontWeight};
  font-family: ${props => props.theme.actionButtonFontFamily};
  border: ${props => props.theme.actionButtonBorder};
  border-radius: ${props => props.theme.actionButtonBorderRadius}px;
  text-align: center;
  font-size: ${props => props.theme.actionButtonFontSize}px !important;
  transition: background ease-out 0.3s;

  &:hover,
  &:active {
    background: rgba(0, 120, 215, 0.08);
  }

  &:disabled {
    border: 1px solid #bbb;
    background: #eee;
    color: #999;
    cursor: default;
  }
`;
