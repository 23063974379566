import styled from 'styled-components';
import SendIcon from '../../assets/icons/send-icon.png';
import MenuIcon from '../../assets/icons/menu.png';
import MenuIconOn from '../../assets/icons/menu-on.png';

export const StyledForm = styled.form`
  position: relative;
  bottom: 0;
  border-top: ${props => props.theme.inputBorder};
  width: 100%;
  height: ${props => props.theme.inputHeight}px;
  background: #fff;
  border-radius: 0 0 ${props => props.theme.chatContainerBorderRadius}px
    ${props => props.theme.chatContainerBorderRadius}px;
  @media (max-width: 576px) {
    border-radius: 0;
    box-sizing: border-box;
    z-index: 1;
    bottom: 0;
  }
`;
export const StyledPersistentMenu = styled.div`
  display: ${props => (props.theme.showHamburger ? 'block' : 'none;')};
  position: absolute;
  z-index: 3;
  margin-left: 10px;
  width: 30px;
  height: ${props => props.theme.inputHeight}px;
  background: url(${MenuIcon}) no-repeat center center;
  background-size: 17px;
  transition: background ease-out 0.3s;
  cursor: pointer;
  &:hover {
    background: url(${MenuIconOn}) no-repeat center center;
    background-size: 17px;
  }
`;

export const StyledInput = styled.input`
  font-size: 15px;
  color: black;
  box-sizing: border-box;
  width: 100%;
  font-weight: 300;
  height: ${props => props.theme.inputHeight}px;
  border: 0;
  padding: 0 50px 4px ${props => props.theme.inputHeight}px;
  border-radius: 0 0 ${props => props.theme.chatContainerBorderRadius}px
    ${props => props.theme.chatContainerBorderRadius}px;
  outline: 0;
  &::placeholder {
    opacity: 0.5;
  }
  &:focus {
    border: 0 !important;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.08);
    &::placeholder {
      opacity: 0;
    }
  }
  @media (max-width: 576px) {
    border-radius: 0;
  }
`;

export const StyledPersistentMenuPopover = styled.div`
  ${props => (props.isOpen ? 'display: initial;' : 'display: none;')}
  position: absolute;
  background: #fff;
  bottom: 40px;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -8px;
    left: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
  }
`;

export const StyledPersistentMenuLink = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #0078d7;
  height: 40px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: 0;
  }
`;

export const StyledSendIcon = styled.div`
  width: 50px;
  height: ${props => props.theme.inputHeight}px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 50px;
  background: url(${SendIcon}) no-repeat center center transparent;
  background-size: ${props => props.theme.sendImageSize}px;
  outline: none;
  font-size: 0;
  position: absolute;
  right: 0;
  z-index: 2;
  filter: opacity(0.5);
  cursor: pointer;
  transition: all ease-out 0.3s;
  &:hover {
    filter: opacity(1);
  }
`;
