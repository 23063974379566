import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledText, StyledTextWrapper, StyledTextImage } from './styles';
import { IThemeContext } from '../../types';
import { containsValidHTMLString } from '../../util';
import sanitizeHtml from 'sanitize-html';

interface ITextMessage {
  text: string;
  isBotMessage: boolean;
}

const TextMessage: React.FC<ITextMessage> = ({ text, isBotMessage }) => {
  const themeContext: IThemeContext = useContext(ThemeContext);

  const imgUrl =
    themeContext?.avatarImageUrl ||
    'https://s3.amazonaws.com/client-images.webchat/carlabs_avatar.png';

  const renderMessage = () => {
    if (containsValidHTMLString(text)) {
      return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}></div>;
    }
    return text;
  };

  return (
    <StyledTextWrapper isBotMessage={isBotMessage} className="StyledTextWrapper">
      {isBotMessage && <StyledTextImage src={imgUrl} />}
      <StyledText isBotMessage={isBotMessage}>{renderMessage()}</StyledText>
    </StyledTextWrapper>
  );
};

export default TextMessage;
