import React, { useState } from 'react';
import { hooks } from 'botframework-webchat-component';
import {
  StyledCompactListWrapper,
  StyledListItem,
  StyledTitle,
  StyledSubtitle,
  StyledContent,
  StyledListValue,
  StyledText,
  StyledBar,
  StyledBarFill,
  StyledImage,
  StyledImageWrapper,
  StyledShowMoreButton,
} from './styles';
import { CompactListMode } from './constants';
import { IAttachmentContent, IActivity } from '../../types';
import { isValidURL } from '../../util';

const { useSendPostBack } = hooks;

const numberOfItemsToShowInitially = 4;

interface ICompactListProps {
  mode?: CompactListMode;
  activity: IActivity;
}

const CompactList: React.FC<ICompactListProps> = ({ activity, mode }) => {
  const sendPostBack = useSendPostBack();
  const [showAll, setShowAll] = useState(false);
  const isSelectMode = mode === CompactListMode.Select;
  const isCompareMode = mode === CompactListMode.Compare;

  const handleItemClick = (content: IAttachmentContent): void => {
    if (!content.buttons) return;
    const button = content.buttons[0];
    const { title, value } = button;

    if (isValidURL(value)) {
      window.open(value);
      return;
    }

    sendPostBack({ title, value });
  };

  const attachmentsLength = activity.attachments.length;
  return (
    <StyledCompactListWrapper className="StyledCompactListWrapper">
      {attachmentsLength
        ? activity.attachments
            .slice(0, showAll ? attachmentsLength : numberOfItemsToShowInitially)
            .map(({ content }, key) => (
              <StyledListItem
                onClick={() => handleItemClick(content)}
                key={key}
                select={isSelectMode}
                selected={content.isSelected}
                compare={isCompareMode}
              >
                <StyledContent>
                  <StyledImageWrapper select={isSelectMode}>
                    <StyledImage src={content.images[0].url} alt="car model photo" />
                  </StyledImageWrapper>

                  <StyledListValue>
                    <StyledTitle>{content.title}</StyledTitle>
                    <StyledSubtitle>{content.subtitle}</StyledSubtitle>
                    <StyledText>{content.text}</StyledText>
                  </StyledListValue>
                </StyledContent>
                <StyledBar compare={isCompareMode}>
                  <StyledBarFill width={content.barValue} />
                </StyledBar>
              </StyledListItem>
            ))
        : null}
      {attachmentsLength > numberOfItemsToShowInitially && !showAll ? (
        <StyledShowMoreButton onClick={() => setShowAll(true)}>
          Show {attachmentsLength - numberOfItemsToShowInitially} more...
        </StyledShowMoreButton>
      ) : null}
    </StyledCompactListWrapper>
  );
};

export default CompactList;
