import { hooks } from 'botframework-webchat-component';
import uniqBy from 'lodash.uniqby';
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useWebchatState } from '../../hooks/webchatstate.hooks';
import ChatHeader from '../ChatHeader';
import InputBox from '../InputBox';
import SuggestedActions from '../SuggestedActions';
import { ThemeContext } from 'styled-components';
import { IThemeContext } from '../../types';
import { isValidJSONString, getValueOrUndefined } from '../../util';
import {
  StyledInputArea,
  StyledChatContainer,
  StyledChatLog,
  StyledSuggestedActions,
  StyledChatWrapper,
  StyledAvatarWrapper,
  StyledAvatar,
  StyledAvatarImage,
  StyledAvatarShadow,
} from './styles';
import {
  ICardAction,
  IActivity,
  ActivityTypes,
  LocalStorageKeys,
  EventTriggers,
  ActivityRole,
  IClientBotConfig,
} from '../../types';
import { EventTrackingService } from '../../services/events-tracking';
import Attachment from '../Attachment';
import AnimationWrapper from '../AnimationWrapper';
import BotIsTypingIndicator from '../BotIsTypingIndicator';
import TeaserChat from '../TeaserChat';
import { TeaserWrapper } from '../TeaserChat/styles';
import { setTimeout } from 'timers';
import { Config } from '../../util/config';
import { DropdownWrapper } from '../DropdownMenu/styles';
import DropdownMenu from '../DropdownMenu';

export const getIntroMessage = async (nodeId: string): Promise<IActivity[]> => {
  try {
    const response = await fetch(`${Config.getShopBotUrl()}/message/intro`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(nodeId),
    });
    const responseJson = await response.json();

    if (!responseJson) {
      return [];
    }

    const fakeConversationId = 'api-intro-message';

    return responseJson.map((activity, index) => ({
      type: ActivityTypes.Message,
      id: `${fakeConversationId}|000000${index + 1}`,
      timestamp: new Date().getTime() / 1000,
      channelId: 'directline',
      text: activity.text,
      from: {
        id: `CarlabsShopbot${Config.getEnvironment()}`,
        name: `CarlabsShopbot${Config.getEnvironment()}`,
        role: 'bot',
      },
      conversation: {
        id: fakeConversationId,
      },
      attachmentLayout: activity?.attachmentLayout,
      locale: 'en-GB',
      suggestedActions: [activity?.quickReplies?.actions || []],
      attachments: [
        {
          contentType: 'application/vnd.microsoft.card.hero',
          content: activity?.attachments[0] || {},
        },
      ],
      entities: [
        {
          type: 'metadata',
          action: '',
          customData: null,
        },
      ],
      isIntroMessage: true, // Please do not remove, A simple hack to avoid lot of logic
      channelData: activity.platformPayload,
      replyToId: `${fakeConversationId}|0000000`,
    }));
  } catch (err) {
    console.log(err);
    return [];
  }
};

const { useActivities, useSuggestedActions, usePostActivity, useSendPostBack } = hooks;

const savedMessages = localStorage.getItem(LocalStorageKeys.ChatHistory);

const responsesClientTimestamps = {};

interface IWebChatProps {
  botConnected: boolean;
  clientBotConfig: IClientBotConfig;
  conversationId?: string;
}

const WebChat: React.FC<IWebChatProps> = ({ botConnected, clientBotConfig, conversationId }) => {
  const themeContext: IThemeContext = useContext(ThemeContext);
  const [chatContainerOpen, setChatContainerOpen] = useState(false);
  const [showChatTeaser, setShowChatTeaser] = useState(themeContext.showTeaser);
  const webchatState = useWebchatState();
  const [initialMessages, setInitialMessages] = useState<IActivity[]>([]);
  const [activities]: [IActivity[]] = useActivities();
  const postActivity = usePostActivity();
  const [introMessages, setIntroMessages] = useState<IActivity[]>([]);
  const sendPostBack = useSendPostBack();
  const [filteredMessages, setFilteredMessages] = useState<any>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  let suggestedActions: ICardAction[][] = useSuggestedActions();

  useEffect(() => {
    webchatState.setConversationId(conversationId);
  }, [conversationId, webchatState]);

  // When App component has connected to directline, we receive a flag
  // that we have connected. At that point we fire off a post back action
  // with the URL params.
  useEffect(() => {
    if (clientBotConfig.storeMessages) {
      localStorage.setItem(
        LocalStorageKeys.ChatHistory,
        JSON.stringify([...initialMessages, ...activities]),
      );
    }
  }, [activities, clientBotConfig.storeMessages, initialMessages]);

  useEffect(() => {
    let initialMessages$1!: IActivity[];
    if (savedMessages && isValidJSONString(savedMessages)) {
      initialMessages$1 = JSON.parse(savedMessages);
    }

    setInitialMessages(initialMessages$1);
    setChatContainerOpen(themeContext.openBotOnInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onReceiveWindowMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      switch (event.data) {
        case 'TOGGLE_BOT':
          setChatContainerOpen(!chatContainerOpen);
          break;
        case 'OPEN_BOT':
          setChatContainerOpen(true);
          break;
        case 'CLOSE_BOT':
          setChatContainerOpen(false);
          break;
      }
    };

    window.addEventListener(
      'message',
      event => {
        onReceiveWindowMessage(event);
        return () => {
          window.removeEventListener('message', onReceiveWindowMessage);
        };
      },
      false,
    );
  });

  // Used to fire actions based on clients event triggers, i.e fire action on page url match
  const fireEventTriggerActions = useCallback(
    (eventTriggers: EventTriggers[]): void => {
      if (!eventTriggers) return;
      eventTriggers.forEach(trigger => {
        switch (trigger.eventTriggerType) {
          case 'page-url':
            const currentUrl = window.location.href;
            if (trigger.eventTriggerInput === currentUrl) {
              setTimeout(() => {
                sendPostBack(`__setnode__ ${trigger.eventTriggerNode.id}`);
              }, 3000);
            }
            break;

          default:
            break;
        }
      });
    },
    [sendPostBack],
  );

  // Used to append client script to body
  const appendClientInjectionScript = useCallback((clientScriptSrc: string): void => {
    if (!clientScriptSrc) return;

    const scriptTag = document.createElement('script');
    scriptTag.src = clientScriptSrc;

    const body = document.getElementsByTagName('body')[0];
    if (!body) return;

    body.append(scriptTag);
  }, []);

  useEffect(() => {
    if (botConnected && clientBotConfig.initNodeId && clientBotConfig.styles.openBotOnInit) {
      getIntroMessage(clientBotConfig.initNodeId).then(messages => {
        setIntroMessages(messages);
      });
    }

    if (botConnected) {
      fireEventTriggerActions(clientBotConfig.eventTriggers);
      appendClientInjectionScript(clientBotConfig.scriptInjectionSource);
    }
  }, [
    botConnected,
    postActivity,
    sendPostBack,
    clientBotConfig.initNodeId,
    clientBotConfig.eventTriggers,
    fireEventTriggerActions,
    appendClientInjectionScript,
    clientBotConfig.scriptInjectionSource,
    clientBotConfig.styles.openBotOnInit,
  ]);

  useEffect(() => {
    const currWindowWidth = window.innerWidth;
    const body = document.getElementsByTagName('body')[0];
    const head = document.getElementsByTagName('head')[0];

    if (body && currWindowWidth <= 576) {
      const hasFullscreenClassName = body.classList.contains('carlabs-bot-fullscreen');
      if (chatContainerOpen) {
        if (!hasFullscreenClassName) {
          body.classList.add('carlabs-bot-fullscreen');

          // temporary test
          const meta = document.createElement('meta');
          meta.name = 'viewport';
          meta.content =
            'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0';
          meta.id = 'carlabs-full-screen-meta';
          if (head) head.append(meta);
        }
      } else {
        if (hasFullscreenClassName) {
          body.classList.remove('carlabs-bot-fullscreen');
          const meta = document.getElementById('carlabs-full-screen-meta');
          if (meta) meta.remove();
        }
      }
    }
  }, [activities, chatContainerOpen]);

  useEffect(() => {
    if (chatContainerOpen && !filteredMessages.length && clientBotConfig.initNodeId) {
      getIntroMessage(clientBotConfig.initNodeId).then(messages => {
        setIntroMessages(messages);
      });
    }
  }, [chatContainerOpen, clientBotConfig.initNodeId, filteredMessages, sendPostBack]);

  const toggleChatContainer = (): void => {
    const newChatContainerState = !chatContainerOpen;
    EventTrackingService.Instance.emit({
      name: newChatContainerState ? 'BOT_STATE__OPENED' : 'BOT_STATE__CLOSED',
    });
    setChatContainerOpen(newChatContainerState);
  };

  const toggleChatContainerAndSendMessage = (message, showChat) => {
    // show chat window and send message
    toggleChatContainer();
    setShowChatTeaser(false);
    if (showChat && message) {
      setTimeout(() => {
        sendPostBack(message);
      }, 300);
    }
  };

  if (clientBotConfig.debugMode) console.log('<CarLabsChat> activities: ', activities);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  useEffect(scrollToBottom, [filteredMessages]);

  const isInitialMessageEnabled = () => {
    const url = new URL(window.location.href);
    return !!url.searchParams.get('init_action');
  };

  const awaitingBotResponse = (activities: IActivity[]) => {
    const lastActivity = [...activities].pop();
    // TODO incorprate has user sent message here;
    if (!lastActivity) {
      return isInitialMessageEnabled();
    }
    return lastActivity?.from?.role === ActivityRole.User;
  };

  const getTimeStampForReactRendering = (activity: IActivity) => {
    if (activity.channelData?.clientTimestamp) {
      return new Date(activity.channelData.clientTimestamp).getTime();
    }
    const responsesClientTimestamp = responsesClientTimestamps[activity.id];
    if (responsesClientTimestamp) {
      return responsesClientTimestamp;
    }
    const newTimeStamp = new Date().getTime();
    responsesClientTimestamps[activity.id] = newTimeStamp;
    return newTimeStamp;
  };

  const awaiting = awaitingBotResponse(activities);

  useEffect(() => {
    activities
      // Filter for type "message"
      .filter(({ type }) => type === ActivityTypes.Message)
      .map(activity => ({
        ...activity,
        attachments: activity.attachments || [],
        text:
          getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') ||
          activity.text,
        timestamp: getTimeStampForReactRendering(activity),
      }))
      // Filter out all empty messages (no attachments or text)
      // Filter for setaction message that we send on init (example is DealerSocket init messages)
      .filter(
        ({ attachments, text }) => (attachments.length || text) && !/__setaction__/.test(text),
      )
      // Filter for setnode message that we send on init (example is DealerSocket init messages)
      .filter(({ attachments, text }) => (attachments.length || text) && !/__setnode__/.test(text))
      // Filter for geolocation coordinates
      .filter(
        ({ attachments, text, value }) =>
          (attachments.length || text || value) && !/__getUserCoordinates__/.test(value),
      )
      // Filter out messages with no attachment types
      // .filter(({ attachmentLayout }) => !!attachmentLayout)
      .filter(({ id }) => !!id)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(activity => {
        if (activity.channelData?.disableInput) webchatState.setInputDisabled(true);
        if (activity.from.role !== 'user' && !activity.channelData?.disableInput)
          webchatState.setInputDisabled(false);
        return setFilteredMessages(prevItems => uniqBy([...prevItems, activity], 'id'));
      });
  }, [activities, webchatState]);

  const messagesToPresent = [...introMessages, ...filteredMessages];

  const messageRender = messagesToPresent.map((activity, index) => {
    return (
      <AnimationWrapper key={index}>
        <Attachment activity={activity} />
      </AnimationWrapper>
    );
  });

  let suggestedActionsShowing = !!(suggestedActions[0] && suggestedActions[0].length);

  if (!suggestedActionsShowing) {
    suggestedActionsShowing = messagesToPresent[messagesToPresent.length - 1]?.isIntroMessage;
    if (suggestedActionsShowing) {
      suggestedActions =
        introMessages.find(({ suggestedActions }) => suggestedActions[0].length > 0)
          ?.suggestedActions || [];
    }
  }

  return (
    <>
      <DropdownWrapper className="DropdownWrapper">
        <DropdownMenu></DropdownMenu>
      </DropdownWrapper>
      <TeaserWrapper isOpen={showChatTeaser} className="teaserWrapper3">
        <TeaserChat isOpen={showChatTeaser} toggleFunction={toggleChatContainerAndSendMessage} />
      </TeaserWrapper>
      <div style={{ display: showChatTeaser ? 'none' : 'block' }} className="ChatWrapperDiv">
        <StyledChatWrapper isOpen={chatContainerOpen} className="StyledChatWrapper">
          <ChatHeader onClick={toggleChatContainer} />
          <StyledChatContainer className="StyledChatContainer">
            <StyledChatLog className="StyledChatLog">
              {messageRender}
              {awaiting ? <BotIsTypingIndicator /> : null}
              <div ref={messagesEndRef} />
            </StyledChatLog>

            {suggestedActionsShowing ? (
              <StyledSuggestedActions className="StyledSuggestedActions">
                <SuggestedActions suggestedActions={suggestedActions} />
              </StyledSuggestedActions>
            ) : null}

            <StyledInputArea className="StyledInputArea">
              <InputBox />
            </StyledInputArea>
          </StyledChatContainer>
        </StyledChatWrapper>
        <StyledAvatarWrapper
          isChatOpen={chatContainerOpen}
          onClick={toggleChatContainer}
          className="StyledAvatarWrapper3"
        >
          <StyledAvatar className="StyledAvatar3">
            <StyledAvatarImage className="StyledAvatarImage3" />
            <StyledAvatarShadow className="StyledAvatarShadow3" />
          </StyledAvatar>
        </StyledAvatarWrapper>
      </div>
    </>
  );
};

export default WebChat;
