import styled from 'styled-components';
import { HorizontalScrollArrowsDirection } from './constants';
import { IThemeContext } from '../../types';

export const StyledSuggestedActionsWrapper = styled.div`
  height: ${props => props.theme.suggestedActionsHeight + 2}px;
`;

export const StyledSuggestedActionButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: ${props => props.theme.actionButtonsDistance}px;
  transition: opacity ease-out 0.4s;
  font-size: 0;
  height: ${props => props.theme.suggestedActionsHeight - 2}px;

  &:active {
    opacity: 0.5;
  }
`;

const getArrow = (direction: HorizontalScrollArrowsDirection, theme: IThemeContext) => {
  if (direction === HorizontalScrollArrowsDirection.Left) {
    return theme.suggestedActionsArrowImageUrlLeft;
  }
  return theme.suggestedActionsArrowImageUrlRight;
};

export const StyledArrow = styled.div`
  width: ${props => props.theme.suggestedActionsArrowWidth}px;
  height: ${props => props.theme.suggestedActionsHeight}px;
  border: ${props => props.theme.suggestedActionsArrowBorder};
  /*This should be display: none but I dont want to make the UI too jumpy*/
  background: ${props =>
    props.isArrowVisible
      ? `url(${getArrow(props.direction, props.theme)}) no-repeat center center`
      : ''};
  background-color: ${props => props.theme.suggestedActionsArrowBackgroundColor};
  background-size: ${props => props.theme.suggestedActionsArrowSize}px;
  cursor: ${props => (props.isArrowVisible ? 'pointer' : 'default')};
  transition: opacity ease-out 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;
