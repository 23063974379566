import React, { useState, useEffect, Fragment } from 'react';
import PostBackButton from '../PostBackButton';
import { IActivity } from '../../types';
import TextMessage from '../TextMessage';
import { messageIsFromBot } from '../../util';
import { StyledSelect } from './styles';

interface ISingleItemDropDownProps {
  activity: IActivity;
}

const SingleItemDropDown: React.FC<ISingleItemDropDownProps> = ({ activity }) => {
  const [selectedValue, setSelectedValue] = useState<string>('Select Option');
  const [selectedTitle, setSelectedTitle] = useState<string>('Submit');
  const [selectDisabled, setSelectDisabled] = useState<boolean>(false);

  useEffect(() => {
    setSelectedValue(activity?.attachments[0]?.content.buttons?.[0]?.value || 'Select Option');
    setSelectedTitle(activity?.attachments[0]?.content.buttons?.[0]?.title || 'Submit');
  }, [activity]);

  const handleDropDownItemSelect = () => {
    // The selection is sent back via the PostBackButton
    // This is used to disable the dropdown and prevent
    // any changes to the selection after a postBack was sent.
    setSelectDisabled(true);
  };

  return (
    <Fragment>
      {activity?.attachments[0]?.content.text ? (
        <TextMessage
          text={activity.attachments[0].content.text}
          isBotMessage={messageIsFromBot(activity.from.role)}
        />
      ) : null}
      <StyledSelect
        onChange={e => setSelectedValue(e.target.value)}
        value={selectedValue}
        disabled={selectDisabled}
      >
        {activity?.attachments[0]?.content.buttons?.map((value, index) => (
          <option key={index} value={value.value}>
            {value.title}
          </option>
        ))}
      </StyledSelect>
      <div className="button-wrapper" onClick={handleDropDownItemSelect}>
        <PostBackButton
          cardAction={{
            value: selectedValue,
            title: selectedTitle,
          }}
        />
      </div>
    </Fragment>
  );
};

export default SingleItemDropDown;
