import styled from 'styled-components';

export const StyledChatHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.headerSize}px;
  background: ${props => props.theme.headerBackgroundColor};
  padding: 0 ${props => (props.theme.headerSize - props.theme.headerTextSize) / 2}px;
  border-radius: ${props => props.theme.chatContainerBorderRadius}px
    ${props => props.theme.chatContainerBorderRadius}px 0 0;
  box-sizing: border-box;
  @media (max-width: 576px) {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    border-radius: 0;
  }
`;

export const StyledChatHeader = styled.div`
  flex: 0 1 auto;
  font-size: ${props => props.theme.headerTextSize}px;
  color: ${props => props.theme.headerTextColor};
  font-weight: 300;
  font-family: ${props => props.theme.headerTextFontFamily};
`;

export const StyledCloseButton = styled.div`
  color: #fff;
  font-size: ${props => props.theme.closeButtonSize}px;
  transform: rotate(45deg);
  cursor: pointer;
  opacity: 0.5;
  transition: opacity ease-out 0.3s;
  &:hover {
    opacity: 1;
  }
`;
