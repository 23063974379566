import styled, { css } from 'styled-components';

const getTextBubbleRadius = props => {
  if (props.isFirst) {
    // 8px {r}px {r}px 8px
    return `8px ${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px  4px`;
  } else if (props.isLast) {
    // 4px {r}px {r}px 8px;
    return `4px ${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px  8px`;
  } else {
    // 4px {r}px {r}px 4px
    return `4px ${props.theme.textBubbleRadius}px ${props.theme.textBubbleRadius}px  4px `;
  }
};

const getTeaserBackground = props => {
  if (props.theme.teaserStyle.showBackground === false) {
    return 'none';
  }

  if (props.theme.teaserStyle.backgroundImageUrl) {
    return `url(${props.theme.teaserStyle.backgroundImageUrl})`;
  } else if (props.theme.chatContainerBackgroundImageUrl) {
    return `url(${props.theme.chatContainerBackgroundImageUrl})`;
  } else if (props.theme.teaserStyle.backgroundColor) {
    return `${props.theme.teaserStyle.backgroundColor}`;
  } else {
    return '#f44336';
  }
};

export const TeaserItem = css`
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

export const TeaserWrapper = styled.div`
  right: 20px;
  position: fixed;
  z-index: 2;
  bottom: 25px;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  @media (max-width: 576px) {
    z-index: 1;
  }
`;

export const TeaserBody = styled.div`
  background: ${props => getTeaserBackground(props)};
  display: flex;
  justify-content: center;
  padding-bottom: 18px;
  padding-top: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 12px;
  width: auto;
`;

export const TeaserActionButton = styled.button`
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0;
  padding: 12px;
  margin: 5px;
  background: ${props => props.theme.teaserStyle.buttonBackgroundColor};
  border: none;
  border-radius: ${props => props.theme.teaserStyle.buttonBorderRadius}px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  transition: background ease-out 0.3s;
  /* background-image: linear-gradient(to bottom, #e52d27 0%, #e52d27 60%,  #b31217 100%);*/

  flex: 0 0 auto;
  width: auto;

  &:hover,
  &:active {
    background-position: right center;
  }

  &:disabled {
    border: none;
    background: #eee;
    color: #999;
    cursor: default;
  }
`;
export const TeaserContainer = styled.div`
  display: grid;
  width: 100%;
  height: auto;
`;

export const TeaserHeader = styled.div`
  ${TeaserItem};
  grid-area: header;
`;

export const TeaserFooter = styled.div`
  ${TeaserItem};
  grid-area: footer;
  grid-row: 4 / 4;
  grid-column: 1 / 4;
  background-color: transparent;
  justify-content: center;
`;

export const TeaserQrbs = styled.div`
  ${TeaserItem};
  grid-area: qrbs;
  grid-row: 3 / 4;
  grid-column: 1 / 4;
  justify-content: center;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  display: flex;
  grid-gap: 1rem;
`;

export const TeaserTextMessages = styled.div`
  ${TeaserItem};
  grid-area: text-msgs;
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  justify-content: center;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  display: flex;
  grid-gap: 1rem;
`;

export const TeaserTextMessagesContent = styled.div`
  ${TeaserItem};
  width: 70%;
  height: 100%;
  justify-content: left;
  display: grid;
`;

export const TeaserFooterAvatar = styled.div`
  ${TeaserItem};
  width: 60px;
  height: 100%;
  background: transparent;
  justify-content: center;
`;

export const TeaserFooterInput = styled.div`
  ${TeaserItem};
  width: 70%;
  height: 100%;
`;

export const TeaserFooterClose = styled.div`
  ${TeaserItem};
  width: 60px;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const TeaserMessageInput = styled.input`
  height: 50px;
  border-radius: 25px;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
`;

export const TeaserCloseButton = styled.button`
  border-radius: 25px;
  height: 50px;
  width: 50px;
  background: rgb(101, 101, 101);
  font-size: 20px;
  color: white;
  border: none;
  outline: none;
  margin-left: 10px;
`;

export const TeaserQRBContent = styled.div`
  ${TeaserItem};
  width: 70%;
  height: 100%;
  align-items: flex-end;
  justify-content: left;
  -webkit-align-items: flex-end;
  overflow-x: auto;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TeaserStyledAvatar = styled.div`
  position: absolute;
`;

export const TeaserStyledText = styled.p`
  padding: 10px;
  border-radius: ${props => getTextBubbleRadius(props)};
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-size: ${props => props.theme.teaserStyle.textFontSize}px;
  color: ${props => props.theme.textBotColor};
  background-color: ${props => props.theme.textBotBackgroundColor};
`;

export const TeaserStyledTextWrapper = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-left: 5px;
  right: 30px;
  justify-content: flex-start;
`;

export const NonInteractiveTeaserText = styled.div`
  float: left;
  line-height: 21px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ced4da;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  text-align: center;
  width: 96px;
  padding: 5px 0;
  font-size: 14px;
  bottom: 11px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  position: relative;
  margin-right: 15px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-left-color: #fff;
    border-right: 0;
    margin-top: -9px;
    margin-right: -9px;
  }
`;
