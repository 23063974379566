import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import CardActionButton from '../CardActionButton';
import { StyledSuggestedActionButton, StyledArrow, StyledSuggestedActionsWrapper } from './styles';
import { HorizontalScrollArrowsDirection } from './constants';
import { ICardAction } from '../../types';

let scrollMenuRef: ScrollMenu | null;

interface ISuggestedActionsScrollListProps {
  cardActions: ICardAction[];
}

const SuggestedActionsScrollList = ({ cardActions }: ISuggestedActionsScrollListProps) => {
  let isArrowVisible = false;

  const scrolldown = () => {
    const el = document.getElementsByClassName('StyledMessagesWrapper')[0] as HTMLElement;
    if (el) {
      setTimeout(() => {
        el.lastElementChild?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 1800);
    }
  };

  // Adding `hideSingleArrow` jacks the buttons left and right hence this dirty hack
  if (scrollMenuRef) {
    const { rightArrowVisible, leftArrowVisible } = scrollMenuRef.state;
    isArrowVisible = rightArrowVisible || leftArrowVisible;
  }

  const buildMenuItems = (cardActions: ICardAction[]) =>
    cardActions.map((card, index) => {
      return (
        <StyledSuggestedActionButton key={index} className="StyledSuggestedActionButton">
          <CardActionButton cardAction={card} />
        </StyledSuggestedActionButton>
      );
    });

  const menuItems = buildMenuItems(cardActions);

  return (
    <StyledSuggestedActionsWrapper className="StyledSuggestedActionsWrapper">
      <ScrollMenu
        ref={component => (scrollMenuRef = component)}
        data={menuItems}
        onFirstItemVisible={() => scrolldown()}
        arrowLeft={
          <StyledArrow
            isArrowVisible={isArrowVisible}
            direction={HorizontalScrollArrowsDirection.Left}
            className="StyledArrowLeft"
          />
        }
        arrowRight={
          <StyledArrow
            isArrowVisible={isArrowVisible}
            direction={HorizontalScrollArrowsDirection.Right}
            className="StyledArrowRight"
          />
        }
        hideArrows={true}
        alignCenter={false}
      ></ScrollMenu>
    </StyledSuggestedActionsWrapper>
  );
};

export default SuggestedActionsScrollList;
