import { ChatPositions, AvatarAnimations, IThemeContext, IClientBotConfig } from '../../types';

export const defaultBotStyle: IThemeContext = {
  headerText: 'Chat with me',
  headerSize: 50,
  headerTextColor: '#ffffff',
  headerTextSize: 16,
  headerTextFontFamily: 'Open Sans, sans-serif',
  headerBackgroundColor: '#cc0000',
  closeButtonSize: 24,

  chatContainerWidth: 500,
  chatContainerHeight: 600,
  chatContainerBorderRadius: 20,
  chatContainerBackgroundImageUrl: '',
  chatContainerBackgroundColor: '#fff',

  textBubbleRadius: 20,
  distanceBetweenBubbles: 20,
  textFontSize: 14,
  textFontFamily: 'Open Sans,sans-serif',
  textUserColor: '#fff',
  textBotColor: '#000',
  textUserBackgroundColor: '#2196F3',
  textBotBackgroundColor: '#eee',

  suggestedActionsBackgroundColor: '#fff',
  suggestedActionsBorder: '1px solid #cccccc',
  suggestedActionsHeight: 46,
  actionButtonsDistance: 10,
  actionButtonBorder: '1px solid #adadad',
  actionButtonBorderRadius: 10,
  actionButtonColor: '#0078d7',
  actionButtonBackgroundColor: '#fff',
  actionButtonFontSize: 14,
  actionButtonFontWeight: 400,
  actionButtonFontFamily: 'Segoe UI, sans-serif',
  suggestedActionsArrowWidth: 25,
  suggestedActionsArrowBorder: 'none',
  suggestedActionsArrowBackgroundColor: '#fff',
  suggestedActionsArrowImageUrlLeft:
    'https://s3.amazonaws.com/client-images.webchat/left-arrow.png',
  suggestedActionsArrowImageUrlRight:
    'https://s3.amazonaws.com/client-images.webchat/right-arrow.png',
  suggestedActionsArrowSize: 15,

  inputBoxPlaceholderText: 'Type your message here...',
  inputHeight: 50,
  inputBorder: '1px solid #cccccc',
  showHamburger: true,
  sendImageSize: 16,

  avatarSize: 100, // percentage
  showAvatar: true,
  avatarImageUrl: 'https://s3.amazonaws.com/client-images.webchat/carlabs_avatar.png',
  avatarAnimation: AvatarAnimations.Bounce,

  mobileHeight: 100, // precentage

  teaserText: [],
  nonInteractiveTeaserText: '',
  chatPosition: ChatPositions.Right,
  fixedPosition: true,
  teaserButtons: [],
  openBotOnInit: false,
  showTeaser: false,
  interactiveTeaser: false,
  teaserStyle: {
    backgroundColor: '',
    backgroundImageUrl: '',
    textFontSize: 14,
    buttonBorderRadius: 20,
    buttonBackgroundColor: 'yellow',
    showBackground: true,
  },

  showDropdownMenu: false,
  dropdownButtons: [],
  dropdownStyle: {
    avatarSize: 140,
    avatarSizeMobile: 130,
    avatarLeft: -42,
    avatarLeftMobile: 38,
    avatarTop: 15,
    avatarTopMobile: 15,
    height: 35, // percentage
    heightMobile: 15, // percentage
    heightMenuMobile: 25, // percentage
  },
};

export const testClientBotConfig: IClientBotConfig = {
  rootElementId: 'carlabs-root-element',
  initOnLoad: true,
  countryCode: 'in',
  corpId: 'kia',
  clearPreviousSessionOnInit: false,
  id: 'b8e8ae86-cb36-4c3e-9abc-ccd43278668c',
  initNodeId: '1aa2ea13-b13d-4f93-9b42-a2d1add7e92b',
  eventTriggers: [],
  scriptInjectionSource: '',
  debugMode: false,
  label: 'Some test label',
  hierarchyElementId: 15,
  styles: {
    headerText: 'Chat with me',
    headerSize: 40,
    headerTextColor: '#33aa44',
    headerTextSize: 16,
    headerTextFontFamily: 'Comic Sans MS',
    headerBackgroundColor: '#c00000',
    closeButtonSize: 24,

    chatContainerWidth: 500,
    chatContainerHeight: 600,
    chatContainerBorderRadius: 20,
    chatContainerBackgroundImageUrl:
      'https://s3.amazonaws.com/client-images.webchat/background_image_VW.png',
    chatContainerBackgroundColor: '#ffffff',

    textBubbleRadius: 20,
    distanceBetweenBubbles: 10,
    textFontSize: 14,
    textFontFamily: 'Comic Sans MS',
    textUserColor: '#fff',
    textBotColor: '#000',
    textUserBackgroundColor: '#2196F3',
    textBotBackgroundColor: '#eee',

    suggestedActionsBackgroundColor: '#fff',
    suggestedActionsBorder: '10px solid #aeaeae',
    suggestedActionsHeight: 46,
    actionButtonsDistance: 10,
    actionButtonBorder: '1px solid #adadad',
    actionButtonBorderRadius: 10,
    actionButtonColor: '#0078d7',
    actionButtonBackgroundColor: '#fff',
    actionButtonFontSize: 12,
    actionButtonFontWeight: 700,
    actionButtonFontFamily: 'Segoe UI, sans-serif',
    suggestedActionsArrowWidth: 25,
    suggestedActionsArrowBorder: '1px solid #123456',
    suggestedActionsArrowBackgroundColor: 'lightgreen',
    suggestedActionsArrowImageUrlLeft: '../../assets/icons/left-arrow.png',
    suggestedActionsArrowImageUrlRight: '../../assets/icons/right-arrow.png',
    suggestedActionsArrowSize: 15,

    inputBoxPlaceholderText: 'Type your message here...',
    inputHeight: 40,
    inputBorder: '1px solid #cccccc',
    showHamburger: true,
    sendImageSize: 20,

    avatarSize: 100, //percentage
    showAvatar: true,
    avatarImageUrl: 'https://s3.amazonaws.com/client-images.webchat/kia_avatar.png',
    avatarAnimation: AvatarAnimations.Bounce,

    mobileHeight: 69, // precentage

    teaserText: ['Hi!', 'Ask me something'],
    nonInteractiveTeaserText: 'Talk to me',
    chatPosition: ChatPositions.Right,
    fixedPosition: true,
    teaserButtons: [],
    openBotOnInit: true,
    showTeaser: false,
    interactiveTeaser: false,
    teaserStyle: {
      backgroundColor: '',
      backgroundImageUrl: 'https://s3.amazonaws.com/client-images.webchat/background_image_VW.png',
      textFontSize: 14,
      buttonBorderRadius: 20,
      buttonBackgroundColor: '',
      showBackground: true,
    },
    showDropdownMenu: false,
    dropdownButtons: [],
    dropdownStyle: {
      avatarSize: 140,
      avatarSizeMobile: 130,
      avatarLeft: -42,
      avatarLeftMobile: 38,
      avatarTop: 15,
      avatarTopMobile: 15,
      height: 35, // percentage
      heightMobile: 15, // percentage
      heightMenuMobile: 25, // percentage
    },
  },
  introMessages: ['Hi there', 'Hows it going?'],
  storeMessages: true,
  introVideo: 'https://s3.amazonaws.com/client-videos.webchat/vw_video.mp4',
};
