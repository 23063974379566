import styled from 'styled-components';

export const StyledCarouselItem = styled.div`
  width: 99% !important;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

export const StyledCarouselImage = styled.img`
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  margin: auto;
`;

export const StyledCarouselHeader = styled.h4`
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 10px;
`;

export const StyledCarouselSubheader = styled.p`
  font-family: 'Segoe UI', sans-serif;
  color: #9e9e9e;
  font-size: 14px;
  margin-left: 10px;
`;

export const StyledCarouselNextButton = styled.div`
  right: 20px;
  &::before {
    color: #fff !important;
    font-size: 20px !important;
  }
`;

export const StyledCarouselPrevButton = styled.div`
  left: -20px;
  &::before {
    color: #fff !important;
    font-size: 20px !important;
  }
`;
