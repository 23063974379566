import { Environments, EmbedScript, ActivityRole } from '../types';
import firebase from '../configs/firebase';
import { IPV4, IPV6 } from './regex';
import { Config } from './config';

/**
 * Logger method used throughout app.
 *
 * @param {string} errorMessage Message we want logged.
 * @param {object} error Error object we want logged.
 * @param {boolean} disableInProd Whether the logs should be disabled in prod or not.
 */
export const Logger = (errorMessage: string, disableInProd: boolean, error?: object) => {
  if (disableInProd && Config.getEnvironment() === Environments.Prod) return;

  if (error) console.log(errorMessage, error);
  else console.log(errorMessage);
};

/**
 * Gets config from a data source based on the vendorId supplied.
 *
 * @param {string} vendorUUID Vendor ID to be used when fetching config.
 * @return {Promise<firebase.firestore.DocumentData | null>} The config  returned based on findings or null.
 */
export const getFirestoreSettings = async (
  vendorUUID: string,
): Promise<firebase.firestore.DocumentData | null> => {
  if (!vendorUUID) {
    Logger('<CarLabsChat> No vendor UUID provided...', true);
    return null;
  }

  try {
    const db = firebase.firestore();
    const botsDocumentRef = db.collection('bots').doc(vendorUUID);
    const doc: firebase.firestore.DocumentData = await botsDocumentRef.get();
    if (!doc.exists) return null;
    return doc.data();
  } catch (error) {
    Logger('<CarLabsChat> Error when fetching config from database...', true, error);
    return null;
  }
};

/**
 * This function will follow a path to get a value from an object.
 * If the path leads to "undefined", we will return "undefined" instead.
 *
 * @param object
 * @param path
 */
export const getValueOrUndefined = (object: any, ...path: string[]): any => {
  if (typeof object !== 'undefined' && path.length) {
    const key = path.shift();

    return getValueOrUndefined(object[`${key}`], ...path);
  }
  return object;
};

/**
 * The minifed js script for botframework-web-chat is embedded on
 * our clients webpages. Each script tag has an ID that maps
 * to their vendorId. We fetch that ID from the script tag and use
 * it to set the vendorId in the app.
 *
 * @param {string} attribute The script attribute we want to retrieve.
 * @return {string | null} vendorUUID string if found, else null.
 */
export const getScriptAttribute = (attribute: string): string | null => {
  const scriptTag = document.getElementById(EmbedScript.ScriptId);
  if (!scriptTag) return null;

  switch (attribute) {
    case EmbedScript.ScriptUUID:
      return scriptTag.getAttribute(EmbedScript.ScriptUUID);

    case EmbedScript.ScriptUsage:
      return scriptTag.getAttribute(EmbedScript.ScriptUsage);

    default:
      return null;
  }
};

/**
 * Checks if string is valid URL
 *
 * @param {string} url Url to be tested
 * @return {boolean}
 */
export const isValidURL = (url: string): boolean => {
  // const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //   '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  // return !!pattern.test(url);

  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  );
};

/**
 * Creates the default CarLabs root DOM element.
 *
 * @param {string} rootElementId ID for root we should create.
 */
export const createRootDomElement = (rootElementId: string) => {
  const el = document.createElement('div');
  el.id = rootElementId;
  const body = document.getElementsByTagName('body')[0];
  if (body) body.appendChild(el);
};

/**
 * Returns URLSearchParams found on current page.
 *
 * @returns {URLSearchParams} URLSearchParams.
 */
export const getUrlParams = (): URLSearchParams => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  return url.searchParams;
};

/**
 * Checks if string is valid JSON
 * using a try catch.
 *
 * @argument {string} jsonStr The string we want to test.
 * @returns {boolean} Whether string was valid JSON or not.
 */
export const isValidJSONString = (jsonStr: string): boolean => {
  try {
    const result = JSON.parse(jsonStr);
    if (result && typeof result === 'object') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

/**
 * Checks if string is valid HTML.
 *
 * @param htmlStr The string to be checked.
 */
export const containsValidHTMLString = (htmlStr: string): boolean => {
  return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
    htmlStr,
  );
};

/**
 * Get range.
 * @param {number} start Start nunber.
 * @param {number} end End number.
 */
export const range = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => i + start);

export const messageIsFromBot = (role: ActivityRole) => {
  return role === ActivityRole.Bot;
};

export const getUserIpAddress = async (): Promise<string | void> => {
  try {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const text = await response.text();

    const ipAddressArray = text.match(IPV4) || text.match(IPV6);

    if (ipAddressArray?.length) {
      return ipAddressArray[0].slice(3);
    }

    return '';
  } catch (err) {
    console.log(err);
  }
};

export const getUserAgent = (): string | void => {
  if (!navigator) return;
  return navigator.userAgent;
};
