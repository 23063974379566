import React from 'react';
import List from '../List';
import CompactList from '../CompactList';
import Carousel from '../Carousel';
import SingleItemDropDown from '../SingleItemDropDown';
import { AttachmentLayoutTypes, IActivity } from '../../types';
import Calendar from '../Calendar';
import TextMessage from '../TextMessage';
import { messageIsFromBot } from '../../util';

interface IAttachmentProps {
  activity: IActivity;
}

const Attachment: React.FC<IAttachmentProps> = ({ activity }) => {
  switch (activity.attachmentLayout) {
    case AttachmentLayoutTypes.Carousel:
      return <Carousel activity={activity} />;

    case AttachmentLayoutTypes.CompactList:
      return <CompactList activity={activity} />;

    case AttachmentLayoutTypes.SingleItemDropDown:
      return <SingleItemDropDown activity={activity} />;

    case AttachmentLayoutTypes.List:
      return <List activity={activity} />;

    case AttachmentLayoutTypes.Calendar:
      return <Calendar activity={activity} />;

    default:
      return (
        <TextMessage
          text={activity.value || activity.text}
          isBotMessage={messageIsFromBot(activity.from.role)}
        />
      );
  }
};

export default Attachment;
