import styled, { keyframes } from 'styled-components';

const flyInAnimation = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
`;

export const StyledAnimationWrapper = styled.div`
  animation-duration: 0.5s;
  animation-name: ${flyInAnimation};
`;
