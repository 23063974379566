import { IBotEvents, ButtonClickedEventPayload } from '../../types';

class EventTrackingService {
  private static instance: EventTrackingService;
  private buttonClickBackCallBack!: (payload: ButtonClickedEventPayload) => void;

  setButtonClickBackCallBack(callback: (payload: ButtonClickedEventPayload) => void) {
    this.buttonClickBackCallBack = callback;
  }

  static get Instance() {
    return this.instance || (this.instance = new this());
  }

  emit(event: IBotEvents) {
    if (event.name === 'BOT_ACTION__BUTTON_CLICKED' && this.buttonClickBackCallBack) {
      this.buttonClickBackCallBack(event.payload);
    }
    console.log('Event: ', event);
    // Need a way to get the vendor Id here
    document.dispatchEvent(new CustomEvent(event.name, { detail: event.payload }));
  }
}

export { EventTrackingService };
