import React, { useMemo, useState } from 'react';
import { Components } from 'botframework-webchat-component';
import { createDirectLine } from 'botframework-webchat';
import { createStore } from 'botframework-webchat-core';
import WebChat from '../WebChat';
import Theme from '../../theme';
import { WebchatStateProvider } from '../../hooks/webchatstate.hooks';
import GlobalStyles from './GlobalStyles';
import { IClientBotConfig } from '../../types';

interface IAppProps {
  directlineToken: string;
  clientBotConfig: IClientBotConfig;
}

interface IDirectLine {
  conversationId: string;
}

const App: React.FC<IAppProps> = ({ directlineToken, clientBotConfig }) => {
  const [botConnected, setBotConnected] = useState(false);
  const [directLine, setDirectLine] = useState<IDirectLine>();

  // Create directline connection based on directlineToken from props.
  if (!directLine) {
    setDirectLine(createDirectLine({ token: directlineToken }));
  }

  // Because we need to customize channelData for it to work with our bots
  // we need to create a store and mutate the action if it is a SEND_MESSAGE
  // or POST_ACTIVITY type. We use simpleUpdateIn to mutate the action object.
  const store = useMemo(
    () =>
      createStore({}, () => next => action => {
        action = window.CarLabs__addChannelDataPayload(action);

        // When bot connection fullfilled, we send the botConnected
        // boolean property to Webchat component via props.
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          setBotConnected(true);

          // Keep as reference for sending custom event types on connect fulfilled
          // store.dispatch({
          //   type: 'WEB_CHAT/SEND_EVENT',
          //   payload: {
          //     name: 'webchat/join',
          //     value: { myvalue: "test" }
          //   }
          // });
        }
        return next(action);
      }),
    [],
  );

  return (
    // We are using the "Composer" component here, which all descendants will have access to the Web Chat API by HOC-ing thru "connectToWebChat".
    <Theme clientBotConfig={clientBotConfig}>
      <WebchatStateProvider>
        {!!directLine && (
          <Components.Composer directLine={directLine} store={store}>
            {/*
            Global styles used to serve external CSS styles
            For example, the Carousel modules CSS is here
            instead of being called from an external resource.
          */}
            <GlobalStyles />
            <div className="carlabs-chat-wrapper">
              <WebChat
                botConnected={botConnected}
                conversationId={directLine?.conversationId}
                clientBotConfig={clientBotConfig}
              />
            </div>
          </Components.Composer>
        )}
      </WebchatStateProvider>
    </Theme>
  );
};

export default App;
