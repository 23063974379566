import styled from 'styled-components';
import { robotAvatarIcon } from './assets';

export const DropdownWrapper = styled.div`
  display: ${props => (props.theme.showDropdownMenu ? 'flex' : 'none')};
`;

export const Wrapper = styled.div`
  position: fixed;
  top: ${props =>
    props.theme.dropdownStyle.height ? `${props.theme.dropdownStyle.height}%` : '35%'};
`;

export const MobileWrapper = styled.div`
  position: fixed;
  right: 0%;
  bottom: ${props =>
    props.theme.dropdownStyle.heightMobile ? `${props.theme.dropdownStyle.heightMobile}%` : '15%'};
`;

export const StyledRobot = styled.div`
  position: relative;
  float: left;
  left: ${props =>
    props.theme.dropdownStyle.avatarLeft ? `${props.theme.dropdownStyle.avatarLeft}px` : '-42px'};
  top: ${props =>
    props.theme.dropdownStyle.avatarTop ? `${props.theme.dropdownStyle.avatarTop}px` : '15px'};
  right: 0px;
  width: 120px;
  height: 130px;
  background: transparent url(${robotAvatarIcon}) 0% 0% no-repeat padding-box;
  background-size: ${props =>
    props.theme.dropdownStyle.avatarSize ? `${props.theme.dropdownStyle.avatarSize}px` : '140px'};
  transform: rotate(60deg);

  @media (max-width: 576px) {
    top: ${props =>
      props.theme.dropdownStyle.avatarTopMobile
        ? `${props.theme.dropdownStyle.avatarTopMobile}px`
        : '15px'};
    background-size: ${props =>
      props.theme.dropdownStyle.avatarSizeMobile
        ? `${props.theme.dropdownStyle.avatarSizeMobile}px`
        : '130px'};
    left: ${props =>
      props.theme.dropdownStyle.avatarLeftMobile
        ? `${props.theme.dropdownStyle.avatarLeftMobile}px`
        : '38px'};
    transform: translate(${props => (props.animate ? '-20px' : '0px')})
      scale(${props => (props.animate ? '1.1' : '1')});
    transition: transform 0.3s ease-out;
    float: right;
    &:hover {
      cursor: ${props => (props.animate ? 'default' : 'pointer')};
    }
  }
`;

export const DropdownMenuWrapper = styled.div`
  position: relative;
  clear: both;
  margin: 5px;
  left: 5px;
  margin: 2rem auto;
  float: left;

  @media (max-width: 576px) {
    transform: translate(${props => (props.animate ? '0px, 0px' : '50px, 450px')});
    opacity: ${props => (props.animate ? '1' : '0')};
    transition: all 0.3s ease-out;
    left: -5px;
    float: right;
  }
`;
