import { useEffect } from 'react';
import { useWebchatState } from '../hooks/webchatstate.hooks';
import { Logger } from '../util';

/**
 * Hook used to listen for clicks on various components.
 * Will be used for analytics in the future as well as handling
 * state in some cases (i.e clicking off the hamburger should
 * close the open menu.)
 *
 * @param ref Ref to the component we listen to.
 */
export const useComponentClickListener = (
  ref: React.MutableRefObject<any>,
  position: 'inside' | 'outside',
): void => {
  const webchatState = useWebchatState();

  const handleClick = event => {
    // Listen for clicks inside of the referenced component.
    if (position === 'inside') {
      if (ref.current && ref.current.contains(event.target)) {
        Logger('<CarLabsChat> Inside click detected...', false);
      }
    }

    // Listen for clicks outside of the referenced component.
    if (position === 'outside') {
      if (ref.current && !ref.current.contains(event.target)) {
        // When more click events occur we need to make this modular
        webchatState.setMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick);

    // Cleanup
    return () => document.removeEventListener('mousedown', handleClick);
  });
};
