import React from 'react';
import { StyledTypingIndicatorBlock, StyledTypingIndicatorDot } from './styles';

const BotIsTypingIndicator: React.FC = () => {
  return (
    <StyledTypingIndicatorBlock>
      <StyledTypingIndicatorDot />
      <StyledTypingIndicatorDot />
      <StyledTypingIndicatorDot />
    </StyledTypingIndicatorBlock>
  );
};

export default BotIsTypingIndicator;
